import { Box, IconButton, Typography } from '@mui/material';
import { SvgColor } from 'src/components/svg-color';
import { CONFIG } from 'src/config-global';

const ChatPlaceholder = ({ isWindow = false }) => (
  <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        mt: isWindow ? 8 : 20,
      }}
    >
      {/* <Box
        component="img"
      
        src={`${CONFIG.site.imageBasePath}/assets/icons/navbar/ic-chat.svg`}
        sx={{ color: 'primary', width: 72, height: 72 }}
      /> */}

      <SvgColor
        sx={{ width: 48, height: 48, color: 'primary.main' }}
        src={`${CONFIG.site.imageBasePath}/assets/icons/navbar/ic-chat.svg`}
        loading="lazy"
        fetchpriority="low"
      />

      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: 'var(--subtitle2line-height)',
          letterSpacing: 'var(--subtitle2letter-spacing)',
          textAlign: 'center',
          color: 'primary.main',
        }}
        gutterBottom
      >
        Zango Copilot
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: 'var(--subtitle2line-height)',
          letterSpacing: 'var(--subtitle2letter-spacing)',
          textAlign: 'center',
          color: 'var(--text-secondary, #637381)',
        }}
      >
        {/* choose policies you want to talk with or chat with law library{' '} */}
      </Typography>
    </Box>
  </>
);

export default ChatPlaceholder;
