import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CONFIG } from 'src/config-global';
import { varAlpha } from 'src/theme/styles';

// ----------------------------------------------------------------------

export function EmptyContent({
  sx,
  imgUrl,
  action,
  filled,
  slotProps,
  description,
  title = 'No data',
  ...other
}) {
  return (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      sx={{
        px: 3,
        py: 8,
        height: 1,
        ...(filled && {
          borderRadius: 2,
          bgcolor: (theme) => varAlpha(theme.vars.palette.grey['500Channel'], 0.04),
          border: (theme) => `dashed 1px ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
        }),
        ...sx,
      }}
      {...other}
    >
      <Box
        component="img"
        alt="empty content"
        src={imgUrl ?? `${CONFIG.site.imageBasePath}/assets/icons/empty/ic-alerts.svg`}
        sx={{
          width: 1,
          maxWidth: 160,
          ...slotProps?.img,
          filter: 'grayscale(1) brightness(1.5) contrast(0.9)',
          opacity: 0.6,
        }}
      />

      {title && (
        <Typography
          variant="h6"
          component="span"
          sx={{
            mt: 3,
            textAlign: 'center',
            ...slotProps?.title,
            color: 'text.primary',
          }}
        >
          {title}
        </Typography>
      )}

      {description && (
        <Typography
          variant="caption"
          sx={{
            mt: 1,
            textAlign: 'center',
            color: 'text.secondary',
            fontSize: '14px',
            ...slotProps?.description,
          }}
        >
          {description}
        </Typography>
      )}

      {action && action}
    </Stack>
  );
}
