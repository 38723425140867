import { CONFIG } from 'src/config-global';
import axiosInstance from 'src/utils/axios';
import { create } from 'zustand';

// Timer Constants (in milliseconds)
const PING_INTERVAL = 10000; // 10 seconds
const RECONNECTION_DELAY = 1000; // 3 seconds
const INACTIVITY_TIMEOUT = 2 * 100 * 1000; // 1 minute
const TAB_INACTIVE_TIMEOUT = 5 * 100 * 1000; // 20 seconds
const CHAT_CONTEXT = `You are an AI compliance assistant specializing in analysing advertisements for regulatory compliance. Your task is to examine advertisement images and determine their compliance with given regulations. Follow these instructions carefully:

1. Regulation Text:
Attached PDF documents contain guidelines on social media promotions. Carefully read and internalize the guidelines. You will use this information to assess the compliance of advertisements.

2. Advertisement Image Analysis:
When presented with an advertisement image, carefully examine its contents. Pay attention to:
- Visual elements (images, graphics, colors)
- Text content
- Layout and design
- Any disclaimers or fine print

3. Compliance Analysis:
Compare the elements of the advertisement to the requirements outlined in the regulation text. Consider:
- Does the ad meet all specified requirements?
- Are there any violations or potential areas of non-compliance?
- Are there any grey areas that require further interpretation?

4. Response Format:
Provide your analysis in the markdown format:

* Describe the key elements of the advertisement
* Provide a detailed evaluation of the advertisement's compliance with the regulation, referencing specific parts of both the ad and the regulation text]
* State whether the advertisement is "Compliant", "Non-compliant", or "Potentially non-compliant"]
* If applicable, provide recommendations for bringing the advertisement into compliance

5. User Queries:
Users may ask specific questions about the advertisement or its compliance. Address these queries using your analysis and the regulation text. Format your response to user queries in markdown, referencing relevant parts of your analysis and the regulation text.

6. Important Notes:
- Always base your analysis on the provided regulation text and the content of the advertisement image.
- If you're unsure about any aspect of the advertisement or its compliance, state this clearly in your analysis.
- Do not make assumptions about information not present in the image or regulation text.
- If you need clarification on any part of the advertisement that you cannot discern from the image, state this in your analysis.

To begin the analysis, I will provide you an advertisement image. After processing the image, please wait for a user query before providing any output.

The advertisement image is below.

User query:`;
// Is the attached advertisement image for social media promotion compliant based on the reguatory guidelines?`
const createChatStore = (set, get) => ({
  socket: null,
  isConnected: false,
  messages: [],
  isLoading: false,
  isExpanded: false,
  isChatActive: true,
  selectedOption: null,
  suggestedQuestions: [],
  pingInterval: null,
  sources: [],
  relatedQuestions: [],
  tags: [],
  socketRef: null,
  pingIntervalRef: null,
  input: '',
  isMarketingChat: false,
  setIsMarketingChat: (value) => set({ isMarketingChat: value }),
  setLoading: (isLoading) => set({ isLoading }),

  // Actions
  setIsExpanded: (value) => set({ isExpanded: value }),
  setIsChatActive: (value) => set({ isChatActive: value }),
  setSelectedOption: (option) => set({ selectedOption: option }),
  setImageData: (data) => set({ imageData: [data] }),
  removeImageData: (index) => set({ imageData: get().imageData.filter((_, i) => i !== index) }),
  removeAllImageData: () => set({ imageData: [] }),
  // Add new action to fetch suggested questions
  fetchSuggestedQuestions: async (id, type) => {
    try {
      const { data } = await axiosInstance.get(
        `/chat/questions${id ? (type === 'policy_id' ? `?policy_id=${id}` : `?alert_id=${id}`) : ''}`
      );
      set({ suggestedQuestions: data });
    } catch (error) {
      set({ suggestedQuestions: [] });
    }
  },

  setInput: (value) => set({ input: value }),

  resetChat: () => {
    const { socketRef, pingIntervalRef } = get();
    set({ messages: [], input: '' });

    // Close socket if it exists and in any state
    if (socketRef) {
      // Close socket regardless of its state
      // if ([WebSocket.CONNECTING, WebSocket.OPEN].includes(socketRef.readyState)) {
      socketRef?.close();
      // }
      set({ socketRef: null });
    }

    set({ isConnected: false });
    set({ isLoading: false });
    // Clear ping interval if exists
    if (pingIntervalRef) {
      clearInterval(pingIntervalRef);
      set({ pingIntervalRef: null });
    }
  },

  resetPing: () => {
    const { socketRef, pingIntervalRef } = get();
    if (pingIntervalRef) {
      clearInterval(pingIntervalRef);
    }

    const newPingInterval = setInterval(() => {
      if (socketRef?.readyState === WebSocket.OPEN) {
        socketRef.send(JSON.stringify({ text: 'ping' }));
      }
    }, PING_INTERVAL);

    set({ pingIntervalRef: newPingInterval });
  },

  initializeWebSocket: (user, id, type) => {
    if (!user || !user.accessToken) return;

    const socket = new WebSocket(
      `${CONFIG.site.webSocketURL}/chat?token=${encodeURIComponent(user.accessToken)}${id ? (type === 'policy_id' ? `&policy_id=${id}` : `&alert_id=${id}`) : ''}`
    );

    socket.onopen = () => {
      set({ isConnected: true });
      get().resetPing();
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        // Handle token expiration
        if (data.type === 'error' && data.message?.toLowerCase().includes('something went wrong')) {
          // get().resetChat();
          set({ isLoading: false });
          return;
        }

        // Rest of your existing onmessage logic
        if (data.type === 'pong') return;
        if (data.message === '' && data.type === 'stream') return;

        set((state) => {
          const currentMessages = state.messages;
          const currentLastMessage = currentMessages[currentMessages.length - 1];

          if (data.type === 'start') {
            set({ isLoading: true });
          }
          if (data.type === 'end') {
            set({ isLoading: false });
          }
          if (currentLastMessage && currentLastMessage.sender === 'bot' && data.sender === 'bot') {
            const updatedMessages = [...currentMessages];
            updatedMessages[updatedMessages.length - 1] = {
              ...currentLastMessage,
              message:
                data.type === 'stream'
                  ? currentLastMessage.message + (data.message || '')
                  : currentLastMessage.message + '',
              citations: data.message?.includes('#### Citations:')
                ? data.message.split('#### Citations:')[1]
                : currentLastMessage.citations,
              sources:
                data.type === 'sources' && data.sub_type === 'ctx_docs'
                  ? data.message
                  : currentLastMessage.sources,
              relatedQuestions:
                data.type === 'related'
                  ? [
                      ...(currentLastMessage.relatedQuestions || []),
                      ...(Array.isArray(data.message) ? data.message : [data.message]),
                    ]
                  : currentLastMessage.relatedQuestions || [],
              tags:
                data.type === 'tag'
                  ? [
                      ...(currentLastMessage.tags || []),
                      ...(Array.isArray(data.message) ? data.message : [data.message]),
                    ]
                  : currentLastMessage.tags || [],
            };
            return { messages: updatedMessages };
          }

          return {
            messages: [
              ...currentMessages,
              {
                ...data,
                message: data.message?.replace(CHAT_CONTEXT, '') || data.message,
                // citations: data.message?.includes('#### Citations:')
                //   ? data.message.split('#### Citations:')[1]
                //   : null,
                // sources:
                //   data.type === 'sources' && data.sub_type === 'ctx_docs' ? data.message : null,
                // relatedQuestions:
                //   data.type === 'related'
                //     ? Array.isArray(data.message)
                //       ? data.message
                //       : [data.message]
                //     : [],
                // tags:
                //   data.type === 'tag'
                //     ? Array.isArray(data.message)
                //       ? data.message
                //       : [data.message]
                //     : [],
              },
            ],
          };
        });
      } catch (error) {}
    };

    socket.onclose = () => {
      set({ isConnected: false });
      // Only attempt reconnection if the chat hasn't been reset
      const state = get();
      // if (state.messages.length > 0) {
      //   setTimeout(() => get().initializeWebSocket(user, id, type), RECONNECTION_DELAY);
      // }
    };

    set({ socketRef: socket });
  },

  sendMessage: (message, user, id, type, removeImageData = false) => {
    const { socketRef, resetPing, initializeWebSocket, imageData } = get();
    if (!message.trim()) return;

    const chatPayload = {
      text: get().isMarketingChat ? CHAT_CONTEXT + message : message,
      ...(imageData && imageData.length > 0 && !removeImageData && { attachments: imageData }),
    };
    set({ isMarketingChat: false });
    if (removeImageData) {
      get().removeAllImageData();
    }
    if (!socketRef || socketRef.readyState !== WebSocket.OPEN) {
      initializeWebSocket(user, id, type);
      const checkAndSendMessage = setInterval(() => {
        const { socketRef: updatedSocketRef } = get();
        if (updatedSocketRef?.readyState === WebSocket.OPEN) {
          resetPing();
          if (chatPayload.attachments) {
            set((state) => {
              const currentMessages = state.messages || [];
              currentMessages.push({
                sender: 'you',
                file: chatPayload.attachments,
                // message: chatPayload.text,
              });
              return { messages: currentMessages };
            });
          }
          updatedSocketRef.send(JSON.stringify(chatPayload));
          set({ input: '' });
          get().removeAllImageData();
          clearInterval(checkAndSendMessage);
        }
      }, 100);
      setTimeout(() => clearInterval(checkAndSendMessage), 5000);
      return;
    }
    resetPing();
    if (chatPayload.attachments) {
      set((state) => {
        const currentMessages = state.messages || [];
        currentMessages.push({
          sender: 'you',
          file: chatPayload.attachments,
          // message: chatPayload.text,
        });
        return { messages: currentMessages };
      });
    }
    socketRef.send(JSON.stringify(chatPayload));
    set({ input: '' });
    get().removeAllImageData();
  },

  handleRelatedQuestionClick: (question, user, id, type) => {
    const { sendMessage } = get();
    sendMessage(question, user, id, type, true);
  },
});

// Export constants for use in other files
export const CHAT_TIMEOUTS = {
  PING_INTERVAL,
  RECONNECTION_DELAY,
  INACTIVITY_TIMEOUT,
  TAB_INACTIVE_TIMEOUT,
};

export const useChatStore = create((set, get) => createChatStore(set, get));
