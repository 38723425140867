// ----------------------------------------------------------------------

export const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  ONBOARDING: '/on-boarding',
  ALERTS: '/alerts',
  ASKZANGO: '/ask-zango',
  CONTROL: '/control',
  GAPANALYSIS: '/gap-analysis',
  INTERNALPOLICIES: '/internal-policies',
  IMPACTASSESMENT: '/impact-assesment',
  LAWLIBRARY: '/law-library',
  RISK: '/risk',
  TASK: '/tasks',
  ZANGOBETA: '/zango-copilot',
  CHANGEPASSWORD: '/firebase/change-password',
  SIGNIN: '/firebase/sign-in',
  VERIFY: '/firebase/verify',
  RESETPASSWORD: '/firebase/reset-password',
  LAWLIBRARYREQUIREMENTS: '/law-library/requirements',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    firebase: {
      signIn: `${ROOTS.AUTH}${ROOTS.SIGNIN}`,
      verify: `${ROOTS.AUTH}${ROOTS.VERIFY}`,
      // signUp: `${ROOTS.AUTH}/firebase/sign-up`,
      resetPassword: `${ROOTS.AUTH}${ROOTS.RESETPASSWORD}`,
      changePassword: `${ROOTS.AUTH}${ROOTS.CHANGEPASSWORD}`,
    },
  },
  onBoarding: {
    root: ROOTS.ONBOARDING,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    alerts: `${ROOTS.DASHBOARD}${ROOTS.ALERTS}`,
    askZango: `${ROOTS.DASHBOARD}${ROOTS.ASKZANGO}`,
    zangoBeta: `${ROOTS.DASHBOARD}${ROOTS.ZANGOBETA}`,
    control: `${ROOTS.DASHBOARD}${ROOTS.CONTROL}`,
    gapAnalysis: `${ROOTS.DASHBOARD}${ROOTS.GAPANALYSIS}`,
    internalPolicies: `${ROOTS.DASHBOARD}${ROOTS.INTERNALPOLICIES}`,
    impactAssesment: `${ROOTS.DASHBOARD}${ROOTS.IMPACTASSESMENT}`,
    lawLibrary: `${ROOTS.DASHBOARD}${ROOTS.LAWLIBRARY}`,
    risk: `${ROOTS.DASHBOARD}${ROOTS.RISK}`,
    task: `${ROOTS.DASHBOARD}${ROOTS.TASK}`,
  },
  subRoutes: {
    lawLibrary: {
      root: `${ROOTS.DASHBOARD}${ROOTS.LAWLIBRARY}`,
      pdf: `${ROOTS.DASHBOARD}${ROOTS.LAWLIBRARY}/pdf`,
      requirements: `${ROOTS.DASHBOARD}${ROOTS.LAWLIBRARYREQUIREMENTS}`,
    },
  },
};
