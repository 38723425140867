import TurndownService from 'turndown';
// import { unified } from "unified";
// import remarkParse from "remark-parse";
// import remarkGfm from "remark-gfm";
// import remarkRehype from "remark-rehype";
// import rehypeSanitize from "rehype-sanitize";
// import rehypeStringify from "rehype-stringify";

import { htmlTags } from './html-tags';

const excludeTags = ['pre', 'code'];

const turndownService = new TurndownService({ codeBlockStyle: 'fenced', fence: '```' });

const filterTags = htmlTags.filter((item) => !excludeTags.includes(item));

/**
 * Custom rule
 * https://github.com/mixmark-io/turndown/issues/241#issuecomment-400591362
 */
turndownService.addRule('keep', {
  filter: filterTags,
  replacement(content, node) {
    const { isBlock, outerHTML } = node;

    return node && isBlock ? `\n\n${outerHTML}\n\n` : outerHTML;
  },
});

// ----------------------------------------------------------------------

export function htmlToMarkdown(html) {
  return turndownService.turndown(html);
}

// ----------------------------------------------------------------------

export function isMarkdownContent(content) {
  // Checking if the content contains Markdown-specific patterns
  const markdownPatterns = [
    /* Heading */
    /^#+\s/,
    /* List item */
    /^(\*|-|\d+\.)\s/,
    /* Code block */
    /^```/,
    /* Table */
    /^\|/,
    /* Unordered list */
    /^(\s*)[*+-] [^\r\n]+/,
    /* Ordered list */
    /^(\s*)\d+\. [^\r\n]+/,
    /* Image */
    /!\[.*?\]\(.*?\)/,
    /* Link */
    /\[.*?\]\(.*?\)/,
  ];

  // Checking if any of the patterns match
  return markdownPatterns.some((pattern) => pattern.test(content));
}
// ----------------------------------------------------------------------

export function fixMarkdownLayout(content) {
  if (!content) return '';

  return (
    content
      // Fix double asterisks spacing
      .replace(/\*\*\s*(\w+(?:\s+\w+)*)\s*\*\*/g, '**$1**')

      // Fix unordered list spacing
      .replace(/^\s*[-*+]\s*/gm, '- ')

      // Fix ordered list spacing
      .replace(/^\s*(\d+)\.\s*/gm, '$1. ')

      // Remove extra newlines between paragraphs (keep max 2)
      .replace(/\n{3,}/g, '\n\n')

      // Fix heading spacing
      .replace(/^(#{1,6})\s*(.+?)\s*$/gm, '$1 $2')

      // Fix inline code spacing
      .replace(/`\s*(\S.*?\S)\s*`/g, '`$1`')

      // Fix blockquote spacing
      .replace(/^\s*>\s*/gm, '> ')

      // Fix horizontal rule spacing
      .replace(/^\s*[-*_]{3,}\s*$/gm, '\n---\n')

      // Fix link/image spacing
      .replace(/\[(.*?)\]\s*\((.*?)\)/g, '[$1]($2)')

      // Trim extra whitespace
      .trim()
  );
}

// Add new function for markdown to HTML conversion
// export function markdownToHTML(markdown) {
//   return unified()
//     .use(remarkParse)
//     .use(remarkGfm)
//     .use(remarkRehype)
//     .use(rehypeSanitize)
//     .use(rehypeStringify)
//     .processSync(markdown)
//     .toString();
// }
