import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { List, Avatar, ListItem, ListItemText, ListItemAvatar } from '@mui/material';

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];
    if (item) {
      props.command({ id: item.id, label: item.name || item.displayName });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <List
      sx={{
        bgcolor: 'background.paper',
        border: 1,
        borderColor: 'grey.300',
        borderRadius: '0.7rem',
        boxShadow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '0.1rem',
        overflow: 'auto',
        padding: '0.4rem',
        position: 'relative',
      }}
    >
      {props.items.map((item, index) => (
        <ListItem
          button
          key={item.id}
          onClick={() => selectItem(index)}
          selected={index === selectedIndex}
          sx={{
            '&:hover': {
              bgcolor: 'grey.200',
            },
            '&.Mui-selected': {
              bgcolor: 'grey.100',
              '&:hover': {
                bgcolor: 'grey.200',
              },
            },
          }}
        >
          <ListItemAvatar>
            <Avatar src={item.avatar} alt={item.displayName} />
          </ListItemAvatar>
          <ListItemText primary={item.displayName} />
        </ListItem>
      ))}
    </List>
  );
});
