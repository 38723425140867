import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Iconify } from 'src/components/iconify';
import { CONFIG } from 'src/config-global';
import { useChatStore } from 'src/zustand-store/chat-store/chat-store';

export const SampleQuestions = memo(
  ({ id, handleRelatedQuestionClick, isSocketReady, user, type }) => {
    const suggestedQuestions = useChatStore((state) => state.suggestedQuestions);
    const fetchSuggestedQuestions = useChatStore((state) => state.fetchSuggestedQuestions);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingIndex, setLoadingIndex] = useState(null);

    useEffect(() => {
      const loadQuestions = async () => {
        setIsLoading(true);
        await fetchSuggestedQuestions(id, type);
        setIsLoading(false);
      };

      loadQuestions();
    }, [fetchSuggestedQuestions, id, type]);

    return (
      <List sx={{ maxWidth: 800, mx: 'auto', mb: 3.5 }}>
        {isLoading ? (
          <Box sx={{ p: 3 }}>
            {[...Array(5)].map((_, index) => (
              <Stack key={index} spacing={2} sx={{ mb: 3 }}>
                <Skeleton variant="text" width="100%" height={24} />
              </Stack>
            ))}
          </Box>
        ) : (
          <>
            {suggestedQuestions.map((question, index) => (
              <ListItem
                key={index}
                sx={{ py: 2, gap: 0, cursor: 'pointer' }}
                onClick={() => {
                  setLoadingIndex(index);
                  if (!loadingIndex) {
                    handleRelatedQuestionClick(question, user, id, type);
                  }
                }}
              >
                {loadingIndex === index ? (
                  <Box
                    component="img"
                    src={`${CONFIG.site.basePath}/assets/images/custom-icon/loading.svg`}
                    sx={{
                      ml: 1,
                      mr: 1.5,
                      width: 18,
                      height: 18,
                      cursor: 'pointer',
                      filter: 'hue-rotate(125deg)',
                    }}
                    loading="lazy"
                    fetchpriority="low"
                  />
                ) : (
                  <ListItemIcon>
                    <Iconify icon="iconoir:flash" sx={{ color: 'primary.main', fontSize: 20 }} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: 'var(--captionline-height)',
                        letterSpacing: 'var(--captionletter-spacing)',
                        textAlign: 'left',
                        color: '#000000',
                      }}
                    >
                      {question}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </>
        )}
      </List>
    );
  }
);
