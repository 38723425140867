import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { markdownClasses } from 'src/components/markdown/classes';

const MarkdownDebugger = ({ content, components, sx, ...other }) => {
  const rehypePlugins = [rehypeRaw, rehypeHighlight, [remarkGfm, { singleTilde: false }]];
  return (
    <StyledRoot
      children={content}
      components={components}
      rehypePlugins={rehypePlugins}
      /* base64-encoded images
       * https://github.com/remarkjs/react-markdown/issues/774
       * urlTransform={(value: string) => value}
       */
      className={markdownClasses.root}
      sx={sx}
      {...other}
    />
  );

  // <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>;
};

export default MarkdownDebugger;
const StyledRoot = styled(ReactMarkdown)(({ theme }) => ({}));
