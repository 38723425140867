import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslate } from 'src/locales';

import { Iconify } from 'src/components/iconify';
import { useRouter } from 'src/routes/hooks';
import useLawLibraryStore from 'src/zustand-store/law-library-store/law-library-store';
import { paths } from 'src/routes/paths';
import { useState } from 'react';
import useRequirementStore from 'src/zustand-store/requirement-store/requirement-store';
import { CONFIG } from 'src/config-global';
import { Tooltip } from '@mui/material';
import { fDate, fTime } from 'src/utils/format-time';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const LawLibraryLeftHeader = ({ currentNav, backButton }) => {
  const router = useRouter();
  const { dataSummary, data } = useLawLibraryStore();
  const { policyDetails, autoSave } = useRequirementStore();
  const [id, setId] = useState(null);
  const { t } = useTranslate('common');
  const getPolicyName = () => {
    if (dataSummary?.name) {
      // setId(dataSummary?.id);
      return dataSummary?.name;
    }
    const id = window.location.pathname?.split('/')?.pop();
    // setId(id);
    const policy = data?.find((item) => item?.id === id);
    return policy?.name || '';
  };
  const policyName = getPolicyName?.() || policyDetails?.name;
  const isRequirement = window?.location?.pathname?.includes(
    paths.subRoutes.lawLibrary.requirements
  );
  const handleNavigation = () => {
    router.push(currentNav?.path);
  };
  const opentest = () => {};
  return (
    <StyledBox>
      {backButton && (dataSummary?.name || isRequirement) ? (
        <Iconify
          onClick={() => handleNavigation()}
          icon="ic:round-arrow-back"
          width={14}
          sx={{ cursor: 'pointer' }}
        />
      ) : (
        currentNav?.icon
      )}
      <Typography
        sx={{ fontWeight: 500, fontSize: '14px', cursor: 'pointer' }}
        onClick={backButton ? () => handleNavigation() : undefined}
      >
        {t(currentNav?.title)} {'   '}
      </Typography>

      {backButton && (dataSummary?.name || isRequirement) ? (
        <>
          <Iconify icon="formkit:right" width={16} />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              maxWidth: '350px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: isRequirement ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (isRequirement) {
                router.replace(paths.subRoutes.lawLibrary.root + '/' + policyDetails?.id);
              }
            }}
            title={policyName}
          >
            {policyName}
          </Typography>
        </>
      ) : null}
      {backButton && isRequirement ? (
        <>
          <Iconify icon="formkit:right" width={16} />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              maxWidth: '350px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={() => opentest()}
          >
            {'Requirements'}
          </Typography>
          {autoSave?.saving ? (
            <>
              {' '}
              <Box
                component="img"
                src={`${CONFIG.site.basePath}/assets/images/custom-icon/loading.svg`}
                sx={{ ml: 1, width: 18, height: 18, filter: 'grayscale(1)', cursor: 'pointer' }}
                loading="lazy"
                fetchpriority="low"
              />{' '}
              {'Saving...'}{' '}
            </>
          ) : (
            <Tooltip
              title={
                fDate(autoSave?.lastSaved)
                  ? `Last updated  ${fDate(autoSave?.lastSaved)}  ${fTime(autoSave?.lastSaved)}`
                  : ''
              }
            >
              <Iconify icon="ic:baseline-cloud-done" width={20} />
            </Tooltip>
          )}
        </>
      ) : null}
    </StyledBox>
  );
};
