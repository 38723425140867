import { useEffect, useState, useCallback } from 'react';
import { Checkbox, Tooltip } from '@mui/material';

import { Iconify } from 'src/components/iconify';
import CommonSearch from '../../core/comman-search';
import { toast } from 'sonner';
import axiosInstance from 'src/utils/axios';
import useAlertStore from 'src/zustand-store/alert-store/alert-store';
import { useBoolean } from 'src/hooks/use-boolean';
import CommonShareBtn from '../comman-share-btn';
import { useTranslate } from 'src/locales';
import { isNovaUser } from 'src/utils/user-type-check';

export default function AlertHeader({ currentNav, backButton }) {
  const { t } = useTranslate();
  let timeoutId;
  const { alertSummary, updateFilters, filters } = useAlertStore();
  const stared = useBoolean(alertSummary?.star || false);
  const [searchValue, setSearchValue] = useState(filters?.name || '');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
  const read = useBoolean(alertSummary?.read || false);
  useEffect(() => {
    stared.setValue(alertSummary?.star || false);
    read.setValue(alertSummary?.read || false);
  }, [alertSummary]);
  const handleChange = async (url) => {
    const isChecked = url === 'star' ? !stared.value : !read.value;
    url == 'star' ? stared.onToggle() : read.onToggle();
    const endpoint = `alerts/${alertSummary.id}/${url}`;
    try {
      const response = await axiosInstance({
        method: isChecked ? 'put' : 'delete',
        url: endpoint,
      });
      if (response.status === 200) {
        toast.success(response.data?.message || t('alerts.statusUpdateSuccess'));
      } else {
        url == 'star' ? stared.onToggle() : read.onToggle();
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      url == 'star' ? stared.onToggle() : read.onToggle();
      toast.error(error.response?.data?.message || t('alerts.statusUpdateError'));
    }
  };

  const handleSelection = (item) => {};

  const handleSearch = useCallback((value) => {
    setSearchValue(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setDebouncedSearchValue(value);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    updateFilters({ name: debouncedSearchValue });
  }, [debouncedSearchValue, updateFilters]);

  useEffect(() => {
    setSearchValue(filters?.name || '');
    setDebouncedSearchValue(filters?.name || '');
  }, [filters?.name]);

  return (
    <>
      {!backButton && <CommonSearch handleSearch={handleSearch} initialValue={searchValue} />}
      {backButton && (
        <Tooltip title={read.value ? t('alerts.markAsUnread') : t('alerts.markAsRead')}>
          <Checkbox
            key="read"
            icon={<Iconify color="#919EAB" icon="mdi:email-outline" />}
            checkedIcon={<Iconify color="#919EAB" icon="mdi:email-open-outline" />}
            checked={read.value}
            onChange={() => {
              handleChange('read');
            }}
            sx={{ p: 0.75 }}
          />
        </Tooltip>
      )}
      {backButton && (
        <Tooltip title={stared.value ? t('alerts.removeStar') : t('alerts.starAlert')}>
          <Checkbox
            color="warning"
            key="star"
            icon={<Iconify color="#919EAB" icon="eva:star-outline" />}
            checkedIcon={<Iconify icon="eva:star-fill" />}
            checked={stared.value}
            onChange={() => {
              handleChange('star');
            }}
            sx={{ p: 0.75 }}
          />
        </Tooltip>
      )}

      {!isNovaUser() && <CommonShareBtn handleSelection={handleSelection} />}
    </>
  );
}
