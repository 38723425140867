import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  Stack,
  CircularProgress,
  Skeleton,
  Divider,
  Button,
} from '@mui/material';
import { Iconify } from 'src/components/iconify';
import useRequirementStore from 'src/zustand-store/requirement-store/requirement-store';
import axiosInstance from 'src/utils/axios';
import { toast } from 'sonner';
import { EmptyContent } from 'src/components/empty-content';
import { REQUIREMENT_PRE_FIX } from 'src/constant/law-library-constant';
import RequirementSourceDrawerContent from './requirement-source-drawer-content';
import { useParams } from 'src/routes/hooks';
import MyPDFViewer from 'src/sections/components/pdf';
import useLawLibraryStore from 'src/zustand-store/law-library-store/law-library-store';

export default function RequirementSourceDrawer({
  requirement,
  hideRequirementId = false,
  hideLink = false,
  sourceButton = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { getArticleById, policyDetails } = useRequirementStore();
  const [source, setSource] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const handleToggle = () => {
    setLoading(true);
    setIsOpen((prev) => !prev);
    // getSource();
  };

  const onClose = () => {
    setIsOpen(false);
  };
  // const getSource = useCallback(async () => {
  //   try {
  //     const imageId = getArticleById(requirement?.article_id, true);
  //     if (!imageId) {
  //       toast.error('Error fetching source document');
  //       return;
  //     }

  //     const response = await axiosInstance.get(`${'/documents/' + imageId}`);
  //     setSource(response.data);
  //   } catch (error) {
  //     console.error('Error fetching source document:', error);
  //     toast.error('Error fetching source document');
  //     setSource(null);
  //   } finally {
  //     setLoading(false);
  //   }

  //   // try {
  //   //     const response = await axiosInstance.get(`/rules/${requirement.id}/sources`);
  //   //     const { data } = response;
  //   //     setSource(data[0]?.content);
  //   // } catch (error) {
  //   //     console.error('Error fetching source:', error);
  //   // }
  // }, [requirement?.id]);

  return (
    <>
      {sourceButton ? (
        <>
          <Button
            variant="soft"
            size="small"
            onClick={handleToggle}
            sx={{
              fontFamily: 'Inter',
              fontSize: 'var(--components-button-small-size, 13px)',
              fontStyle: 'normal',
              fontWeight: 500,
              height: '40px',
              lineHeight: 'var(--components-button-small-line-height, 22px)' /* 169.231% */,
            }}
            startIcon={<Iconify icon="eva:diagonal-arrow-right-up-fill" />}
          >
            {' View Doc'}
          </Button>
        </>
      ) : (
        <Stack direction="row" alignItems="center">
          {!hideRequirementId && (
            <Typography
              sx={{
                color: 'text.secondary',
                fontSize: 'var(--body2-size, 14px)',

                fontWeight: 400,
                mr: 1,
              }}
            >
              {REQUIREMENT_PRE_FIX[0]} {requirement?.short_id}
            </Typography>
          )}
          <Iconify
            onClick={(e) => {
              handleToggle();
            }}
            sx={{
              color: hideLink ? 'text.secondary' : 'primary.main',
              mr: 0.5,
              width: '14px',
              height: '14px',
              cursor: 'pointer',
            }}
            icon="eva:diagonal-arrow-right-up-fill"
          />
          <Typography
            onClick={(e) => {
              handleToggle();
            }}
            sx={{
              overflow: 'hidden',
              color: hideLink ? 'text.secondary' : 'primary.main',
              textOverflow: 'ellipsis',
              fontFamily: 'Inter',
              fontSize: 'var(--body2-size, 14px)',
              fontStyle: 'normal',
              fontWeight: 400,
              cursor: 'pointer',
              lineHeight: 'var(--body2-line-height, 22px)',
              letterSpacing: 'var(--body2-letter-spacing, 0px)',
              mr: 1,
              textDecoration: 'underline',
            }}
          >
            {getArticleById(requirement?.article_id)}
          </Typography>
        </Stack>
      )}
      <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="right"
        PaperProps={{
          sx: {
            backgroundColor: '#ffffff',
            backgroundImage: 'none',
            width: '50%',
            maxWidth: '900px',
            backgroundImage: 'none',
          },
        }}
      >
        {/* <RequirementSourceDrawerContent
          text={getArticleDetailsById(requirement?.article_id)?.text}
          isLoading={isLoading}
          source={source}
          onClose={onClose}
        /> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
          <Typography variant="h6">Source Document</Typography>

          <IconButton onClick={onClose}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>
        <Divider />
        {policyDetails && (
          <MyPDFViewer
            fileUrl={policyDetails?.signed_url}
            pageNumber={requirement?.source || 0}
            // referenceText={getArticleDetailsById(requirement?.article_id)?.text || ''}
          />
        )}
      </Drawer>
    </>
  );
}
