import { Alert, Box, Button, Chip, IconButton, Link, Stack, Typography } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import FeedIcon from '@mui/icons-material/Feed';

import { Iconify } from 'src/components/iconify';
import { Markdown } from 'src/components/markdown';
import { Scrollbar } from 'src/components/scrollbar';
import { useTranslate } from 'src/locales';
import { SummaryRecentTransitions } from 'src/sections/summary/summary-recent-transitions';
import LangChangeFabBtn from 'src/sections/components/lang-change-fab-btn';
import useAlertStore from 'src/zustand-store/alert-store/alert-store';
import { CONFIG } from 'src/config-global';
import { useEffect, useState } from 'react';
import TranslationAlertToast from 'src/sections/components/translation-alert-tost';

export function SourceDrawer({ alert, onClose, lang, onChangeLang, fromAlertSummary = false, id }) {
  const { t } = useTranslate();
  function getTitleFromMarkdown(markdownContent) {
    const titleRegex = /^#\s+(.*)$/m;
    const match = markdownContent.match(titleRegex);
    return match ? match[1].trim() : null;
  }
  const [markdownData, setMarkdownData] = useState(alert?.summary?.content);
  const [isRefiningTranslation, setIsRefiningTranslation] = useState(false);
  const [translationInProgress, setTranslationInProgress] = useState(false);
  const updatedSummary = async () => {
    setIsRefiningTranslation(true);
    setTranslationInProgress(false); // diabled translation confirmation alert
    try {
      const alertSummaryInEnglish = await useAlertStore.getState().fetchAlertSummaryInEnglish(id);
      const linkedSources = alertSummaryInEnglish?.linked_sources;
      const content = linkedSources.filter((item) => item.id === alert.id);
      const data = await useAlertStore
        .getState()
        .retranslations([content[0]?.summary?.content], 'pt');
      setMarkdownData(data[0]);
    } finally {
      setIsRefiningTranslation(false);
    }
  };
  const updateTranslation = () => {
    // const data = await useAlertStore.getState().saveUpdatedTranslations(id, {
    //   summary: markdownData,
    //   lang: 'pt',
    // });
    // setMarkdownData(data[0]);
    setTranslationInProgress(false);
  };
  const cancelTranslation = () => {
    setTranslationInProgress(false);
    setMarkdownData(alert?.summary?.content);
  };
  useEffect(() => {
    setMarkdownData(alert?.summary?.content);
  }, [alert?.summary?.content]);
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {fromAlertSummary && (
          <Box sx={{ display: 'flex', alignItems: 'center', px: 3.5, pt: 2 }}>
            <LangChangeFabBtn currentLang={lang} onChangeLang={onChangeLang} />
          </Box>
        )}
        <IconButton onClick={onClose} sx={{ top: 12, right: 12, zIndex: 9, position: 'absolute' }}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      </Box>

      <Scrollbar sx={{ px: 2.5, py: 2 }}>
        <Stack spacing={1}>
          {alert && (
            <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h4"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {alert.summary?.content && getTitleFromMarkdown(alert?.summary?.content)}
              </Typography>

              {alert.extra && (
                <Stack direction="row" sx={{ textTransform: 'capitalize', pt: 1 }} spacing={1}>
                  {alert.extra?.document_type && (
                    <Chip
                      icon={<FeedIcon />}
                      label={alert.extra?.document_type.replaceAll(/_/g, ' ')}
                      variant="soft"
                    />
                  )}
                  {alert.extra?.alert_type && (
                    <Chip
                      icon={<Iconify icon="material-symbols:label-important-rounded" width={16} />}
                      label={alert.extra?.alert_type.replaceAll(/_/g, ' ')}
                      variant="soft"
                    />
                  )}
                  <Chip icon={<FaceIcon />} color="success" variant="soft" label="Human Verified" />
                </Stack>
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                <Iconify icon="iconoir:link" />
                <Typography>{t('alerts.source')}:</Typography>
                <Typography
                  variant="body2"
                  noWrap
                  component="div"
                  sx={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Link
                    href={alert?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                  >
                    {alert?.url}
                  </Link>
                </Typography>
              </Box>

              <Stack>
                {markdownData && (
                  <Box>
                    <Markdown children={markdownData} />
                    {/* {lang === 'pt'  && fromAlertSummary && (
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            component="img"
                            src={`${CONFIG.site.basePath}/assets/images/custom-icon/language_grey.svg`}
                            sx={{ width: 24, height: 24, color: '#1C252E' }}
                          />
                          <Typography
                            sx={{
                              color: 'var(--text-primary, #1C252E)',
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: '160%',
                              letterSpacing: 'var(--body2-letter-spacing, 0px)',
                            }}
                          >
                            Tradução não precisa?
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 2 }}>
                          <Iconify
                            icon="solar:refresh-bold"
                            color="primary.main"
                            width={24}
                            height={24}
                            sx={{ cursor: 'pointer' }}
                          />

                          <Typography
                            onClick={() => updatedSummary()}
                            sx={{
                              color: 'var(--swap-main-light-primary, #7635CB)',
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: '160%',
                              cursor: 'pointer',
                              letterSpacing: 'var(--body2-letter-spacing, 0px)',
                            }}
                          >
                            {' '}
                            Aprimorar Tradução
                          </Typography>
                        </Box>
                      </Box>
                    )} */}
                  </Box>
                )}
                {alert.timeline && (
                  <SummaryRecentTransitions timeline={alert.timeline.filter((item) => item.date)} />
                )}
              </Stack>
            </Box>
          )}
          {translationInProgress && (
            <TranslationAlertToast
              isRefiningTranslation={isRefiningTranslation}
              cancelTranslation={cancelTranslation}
              updateTranslation={updateTranslation}
            />
          )}
        </Stack>
      </Scrollbar>
    </>
  );
}
