import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Typography, IconButton, Stack, Button } from '@mui/material';

import { useAuthContext } from 'src/auth/hooks';
import { Iconify } from 'src/components/iconify';
import { ChatRenderMessage } from '../chat/chat-render-message';
import { CHAT_TIMEOUTS } from 'src/zustand-store/chat-store/chat-store';
import { SampleQuestions } from '../suggested-faq';
import ChatSidebar from '../chat-sidebar';
import ChatPlaceholder from '../chat-placeholder';
import ChatInputField from '../chat/chat-input-field';
import { CONFIG } from 'src/config-global';
import { useChatStore } from 'src/zustand-store/chat-store/chat-store';

export default function ChatInterface({
  isWindow = false,
  handleClose,
  files,
  id: initialId,
  type,
  copiedText = false,
  chatFrom = '',
}) {
  const [id, setId] = useState(initialId);
  const [defailtText, setDefailtText] = useState(copiedText ? true : false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  const {
    isExpanded,
    messages,
    input,
    isConnected,
    socketRef,
    isLoading,
    setInput,
    resetChat,
    initializeWebSocket,
    sendMessage,
    handleRelatedQuestionClick,
  } = useChatStore();

  const { user } = useAuthContext();
  const messagesEndRef = useRef(null);
  const lastScrollTopRef = useRef(0);

  // Memoize handlers
  const handleScroll = useCallback((e) => {
    const element = e.target;
    const isScrollingUp = element.scrollTop < lastScrollTopRef.current;
    lastScrollTopRef.current = element.scrollTop;

    if (isScrollingUp) {
      setShouldAutoScroll(false);
    }
  }, []);

  const handleSend = useCallback(
    (e, imageData) => {
      e.preventDefault();
      sendMessage(input, user, id, type);
      setShouldAutoScroll(true);
    },
    [input, sendMessage, user]
  );

  const handleDocChange = useCallback(
    (file) => {
      setId(file?.id || null);
      resetChat();
      initializeWebSocket(user, file?.id, type);
    },
    [user, type, resetChat, initializeWebSocket]
  );

  // Combine visibility and inactivity handlers
  useEffect(() => {
    let inactivityTimeout;
    let isComponentMounted = true;

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeout);
      if (isComponentMounted) {
        inactivityTimeout = setTimeout(() => {
          const store = useChatStore.getState();
          if (store.socketRef?.readyState === WebSocket.OPEN) {
            store.socketRef.close();
          }
        }, CHAT_TIMEOUTS.INACTIVITY_TIMEOUT);
      }
    };

    const handleActivity = () => resetInactivityTimeout();

    const handleVisibilityChange = () => {
      const store = useChatStore.getState();
      if (document.hidden) {
        const timeout = setTimeout(() => {
          if (store.socketRef?.readyState === WebSocket.OPEN) {
            store.socketRef.close();
            resetChat();
          }
        }, CHAT_TIMEOUTS.TAB_INACTIVE_TIMEOUT);
        return () => clearTimeout(timeout);
      } else if (!store.socketRef || store.socketRef.readyState !== WebSocket.OPEN) {
        initializeWebSocket(user, id, type);
      }
    };

    // Event listeners
    if (typeof window !== 'undefined') {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      document.addEventListener('keydown', handleActivity);
      document.addEventListener('mousemove', handleActivity);
      document.addEventListener('mousedown', handleActivity);
    }

    // Cleanup function
    return () => {
      isComponentMounted = false;
      clearTimeout(inactivityTimeout);
      if (typeof window !== 'undefined') {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        document.removeEventListener('keydown', handleActivity);
        document.removeEventListener('mousemove', handleActivity);
        document.removeEventListener('mousedown', handleActivity);
      }

      // Cleanup WebSocket and reset chat on unmount
      const store = useChatStore.getState();
      if (store.socketRef?.readyState === WebSocket.OPEN) {
        store.socketRef.close();
      }
    };
  }, [socketRef, resetChat, initializeWebSocket, user, id, type]);

  // Add error boundary for WebSocket
  useEffect(() => {
    const handleWebSocketError = (error) => {
      console.error('WebSocket error:', error);
      // Attempt to reconnect
      const store = useChatStore.getState();
      if (store.socketRef) {
        store.socketRef.close();
        setTimeout(() => {
          // initializeWebSocket(user, id, type);
        }, CHAT_TIMEOUTS.RECONNECTION_DELAY);
      }
    };

    if (socketRef) {
      socketRef.onerror = handleWebSocketError;
    }

    return () => {
      if (socketRef) {
        socketRef.onerror = null;
      }
    };
  }, [socketRef, initializeWebSocket, user, id, type]);
  useEffect(() => {
    return () => {
      if (!defailtText) {
        resetChat();
      }
    };
  }, []);
  // Memoize InfoBox component
  const InfoBox = useCallback(
    () => (
      <Box
        sx={{
          maxWidth: '100%',
          background: 'var(--info-8, #00B8D914)',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'flex-start',
          gap: 2,
          p: 2,
          mx: 4,
          mb: isWindow ? 4 : 2,
        }}
      >
        <Iconify
          icon="ic:outline-info"
          width={24}
          height={24}
          sx={{ color: 'info.main', mt: 0.5 }}
        />
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: 'var(--captionline-height)',
            letterSpacing: 'var(--captionletter-spacing)',
            textAlign: 'left',
          }}
        >
          Your follow-up questions will remember the context of earlier conversations. Start a new
          chat if you want to reset the context.
        </Typography>
      </Box>
    ),
    [isWindow]
  );

  useEffect(() => {
    if (isLoading && messagesEndRef.current && shouldAutoScroll) {
      // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading, messages, shouldAutoScroll]);

  const header = (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        bgcolor: 'background.paper',
        borderBottom: '1px solid',
        borderColor: 'divider',
        px: 2,
        py: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Box
        component="img"
        src={`${CONFIG.site.basePath}/assets/images/custom-icon/chat_normal.svg`}
        sx={{ width: 24, height: 24 }}
      />

      <Box sx={{ flexGrow: 1 }} />
      <Button
        variant="soft"
        color="inherit"
        size="small"
        onClick={() => {
          resetChat();
          setDefailtText(false);
        }}
        startIcon={<Iconify icon="mingcute:add-line" />}
      >
        New Chat
      </Button>
      {/* <Checkbox
      color="warning"
      icon={<Iconify color="#919EAB" icon="eva:star-outline" />}
      checkedIcon={<Iconify icon="eva:star-fill" />}
      sx={{ p: 0.75 }}
    /> */}
      {/* <Checkbox
      icon={<Iconify color="#919EAB" icon="mingcute:fullscreen-2-line" />}
      checkedIcon={<Iconify color="#919EAB" icon="mingcute:fullscreen-exit-2-line" />}
      checked={isExpanded}
      onChange={() => {}}
      sx={{ p: 0.75 }}
    />
    <IconButton color={ 'inherit'} onClick={()=>{}}>
      <Iconify size={24} icon="eva:more-vertical-fill" />
    </IconButton> */}
      <IconButton onClick={handleClose} sx={{ p: 0.75 }}>
        <Iconify icon="eva:close-fill" color="#919EAB" size={24} />
      </IconButton>
    </Box>
  );

  return (
    <Stack direction="row">
      <Box
        sx={{
          minWidth: isExpanded ? 'calc(100% - 360px)' : '100%',
          // height: '90vh',
          // display: 'flex',
          // flexDirection: 'column',
          // position: 'relative',
        }}
      >
        {isWindow && (
          <Box position="sticky" top={0} zIndex={1000}>
            {header}
          </Box>
        )}
        <Box
          sx={
            {
              // flex: 1,
              // overflowY: 'auto',
              // Add padding bottom to prevent content from being hidden behind ChatInput
            }
          }
        >
          {messages.length === 0 ? (
            <ChatPlaceholder isWindow={isWindow} />
          ) : (
            <Stack
              spacing={0}
              sx={{
                width: '100%',
                minHeight: messages.length > 0 ? 'calc(100vh - 180px)' : 'auto',
                // overflowY: 'auto',
                maxWidth: 800,
                p: 2,
                mx: 'auto',
              }}
              onScroll={handleScroll}
            >
              {/* <Alert severity={isConnected ? 'success' : 'error'} sx={{ mb: 2 }}>
              {isConnected ? 'Chat is active and connected' : 'Chat is disconnected'}
            </Alert> */}

              {messages.map((msg, index, l) => (
                <ChatRenderMessage
                  isWindow={isWindow}
                  handleRelatedQuestionClick={handleRelatedQuestionClick}
                  key={index}
                  user={user}
                  isLastMessage={index === l.length - 1}
                  isLoading={isLoading}
                  msg={msg}
                />
              ))}
              {messages[messages.length - 1].sender === 'you' &&
                (messages[messages.length - 1]?.message?.length > 1 ||
                  messages[messages.length - 1]?.tags ||
                  isLoading) && (
                  <Box
                    component="img"
                    src={`${CONFIG.site.imageBasePath}/assets/images/custom-icon/chat_zango.svg`}
                    loading="lazy"
                    fetchpriority="low"
                    sx={{
                      width: 48,
                      height: 48,
                      ml: 3,
                      mt: 0.5,
                    }}
                  />
                )}
              {isLoading && (
                <>
                  <Box
                    component="img"
                    src={`${CONFIG.site.imageBasePath}/assets/images/custom-icon/chat_loading.gif`}
                    loading="lazy"
                    fetchpriority="low"
                    sx={{
                      width: 66,
                      height: 48,
                      mt: 1,
                      mx: 3,
                    }}
                  />
                  <div ref={messagesEndRef} />
                </>
              )}

              {!isLoading && messages.length > 2 && <InfoBox />}
            </Stack>
          )}
        </Box>

        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            //  mx:'auto',
            p: 2,
          }}
        >
          {!isWindow && (
            <ChatInputField
              input={input}
              setInput={setInput}
              handleSend={handleSend}
              isConnected={isConnected}
              isWindow={isWindow}
              files={files}
              chatFrom={chatFrom}
              isLoading={isLoading}
              handleDocChange={handleDocChange}
            />
          )}
          {messages.length === 0 && !defailtText && !chatFrom && (
            <SampleQuestions
              isSocketReady={socketRef?.readyState === WebSocket.OPEN}
              user={user}
              id={id}
              type={type}
              handleRelatedQuestionClick={handleRelatedQuestionClick}
            />
          )}
        </Box>
        {isWindow && (
          <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, p: 0 }}>
            <ChatInputField
              input={input}
              setInput={setInput}
              files={files}
              chatFrom={chatFrom}
              isLoading={isLoading}
              handleDocChange={handleDocChange}
              handleSend={handleSend}
              isConnected={isConnected}
              isWindow={isWindow}
            />
          </Box>
        )}
      </Box>

      {isExpanded && (
        <Box
          sx={{
            width: '360px',
            borderLeft: '1px solid var(--components-paper-outlined, #919EAB1F)',
            flexShrink: 0,
          }}
        >
          <ChatSidebar />
        </Box>
      )}
    </Stack>
  );
}
