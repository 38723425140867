import { Box, Drawer, Stack, Typography } from '@mui/material';
import { Markdown } from 'src/components/markdown';
import { ChatSource } from './chat-source';
import { RelatedQuestions } from './related-questions';
import { Citations } from './chat-citation';
import { useEffect, useState } from 'react';
import { CONFIG } from 'src/config-global';
import MarkdownDebugger from 'src/zustand-store/chat-store/markdown-debugger';
import { useBoolean } from 'src/hooks/use-boolean';
import { toast } from 'sonner';
import { SourceDrawer } from 'src/components/source-drawer';
import axiosInstance from 'src/utils/axios';
import CardShimmerEffect from 'src/components/loading-screen/card-shimmer-effect';

export function ChatRenderMessage({
  msg,
  index,
  handleRelatedQuestionClick,
  isLoading,
  isWindow,
  isLastMessage,
}) {
  const [mdtext, setMdtext] = useState('');
  const openSourceDrawer = useBoolean();
  const [sourceItem, setSourceItem] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setMdtext(removePostfixText(msg.message));
  }, [msg.message]);

  const removePostfixText = (text) => {
    if (!text) return '';
    // Ensure text is a string
    const text2 = String(text);
    // Replace markdown link patterns with custom markdown for onClick
    const textStr = text2.replace(
      /\[([^\]]+)\]\(\/dashboard\/law-library\/pdf\/[^)]+\)/g,
      (match, linkText) => `[${linkText}](#)`
    );

    const citationsMarker = '#### Citations:';
    const parts = textStr.split(citationsMarker);

    // If no citations marker found, return original text
    if (parts.length < 2) return textStr;

    // Return just the first part (before citations), trimmed
    return parts[0].trim();
  };
  const handleSourceClick = async (href) => {
    openSourceDrawer.onTrue();
    setLoading(true);
    try {
      const documentId = href;
      const response = await axiosInstance.get(`/documents/${documentId}`);

      // Check if response status is successful (2xx)
      if (response.status >= 200 && response.status < 300) {
        setSourceItem(response.data);
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      toast.error('Error fetching document');
      openSourceDrawer.onFalse();
    } finally {
      setLoading(false);
    }
  };
  const components = {
    a: ({ children, href }) => (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleSourceClick(href);
        }}
      >
        {children}
      </a>
    ),
    code({ className, children, node, ...other }) {
      const language = /language-(\w+)/.exec(className || '');

      return language ? (
        <code
          {...other}
          className={className}
          style={{ maxWidth: '90%', overflowX: 'auto', display: 'block' }}
        >
          {children}
        </code>
      ) : (
        <code
          {...other}
          style={{
            padding: '2px 4px',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.875rem',
            borderRadius: '2px',
            backgroundColor: 'rgba(145, 158, 171, 0.2)',
            maxWidth: '90%',
            overflowX: 'auto',
            display: 'block',
          }}
        >
          {children}
        </code>
      );
    },
  };
  return (
    <Box
      key={index}
      sx={{
        display: 'flex',
        justifyContent: msg.sender === 'you' ? 'flex-end' : 'flex-start',
        my: msg.sender === 'you' ? 0.25 : msg.message === '' ? 0.25 : 2,
        px: 2,
      }}
    >
      <Box
        sx={{
          maxWidth: !isWindow ? '90%' : '100%',

          backgroundColor: msg.sender === 'you' ? '#C8FAD6' : 'transparent',
          // : msg.sender === 'you'
          //   ? '#C8FAD6'
          //   : 'grey.100',
          borderRadius: 2,

          px: 2,
        }}
      >
        {msg.sender === 'bot' && msg?.message && (
          <Box
            component="img"
            src={`${CONFIG.site.imageBasePath}/assets/images/custom-icon/chat_zango.svg`}
            loading="lazy"
            fetchpriority="low"
            sx={{
              width: 48,
              height: 48,
              mr: 1,
              mt: 1,
            }}
          />
        )}
        {/* {msg?.tags && (
          <Box
            component="img"
            src={`${CONFIG.site.imageBasePath}/assets/images/custom-icon/${msg.tags}.png`}
            alt={msg.tags}
            sx={{
              maxWidth: '100%',
              margin: '100%',
              height: 'auto',
              borderRadius: 1,
              mx: 2,
              my: 1,
              display: 'block',
            }}
          />
        )} */}
        {msg?.file && msg?.file.length > 0 && (
          <>
            {/* Images Section */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'end', gap: 2 }}>
              {msg.file
                .filter((file) => file?.content_type?.startsWith('image/'))
                .map((file, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={`data:${file.content_type};base64,${file.data}`}
                    alt={file.fileName}
                    sx={{
                      maxWidth: '100%',
                      width: '300px',
                      // height: '300px',
                      objectFit: 'cover',
                      borderRadius: 1,
                      my: 2,
                    }}
                  />
                ))}
            </Box>

            {/* Other Files Section */}
            <Box>
              {msg.file
                .filter((file) => !file?.content_type?.startsWith('image/'))
                .map((file, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    sx={{
                      padding: 1,
                      my: 2,
                      borderRadius: 1,
                      objectFit: 'cover',
                      backgroundColor: '#0000000d',
                      maxWidth: 260,
                    }}
                    spacing={1}
                  >
                    <Box
                      component="img"
                      src={`${CONFIG.site.basePath}/assets/icons/files/${
                        file.content_type === 'application/pdf'
                          ? 'ic-pdf.svg'
                          : file.content_type === 'text/csv'
                            ? 'ic-csv.svg'
                            : 'ic-document.svg'
                      }`}
                      alt={file.filename}
                      sx={{
                        width: 40,
                        height: 40,
                        maxWidth: 40,
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '1rem',
                        lineHeight: 1.5,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      title={file.filename}
                    >
                      {file.filename}
                    </Typography>
                  </Stack>
                ))}
            </Box>
          </>
        )}
        {removePostfixText(mdtext) && (
          <MarkdownDebugger
            components={components}
            className="markdown-debugger"
            content={removePostfixText(mdtext) || ''}
          />
        )}
        {/* <Markdown
              components={components}
              sx={{
                fontFamily: 'var(--captionfamily)',
                fontSize: 'var(--captionsize)',
                fontStyle: 'italic',
                fontWeight: 400,
                lineHeight: 'var(--captionline-height)',
                letterSpacing: 'var(--captionletter-spacing)',
                textAlign: 'left',

                borderRadius: 2,
              }}
            >
            {removePostfixText(mdtext) || ''}
            </Markdown> */}
        {msg.citations && (
          <Citations citations={msg.message} handleSourceClick={handleSourceClick} />
        )}

        {msg.sources && <ChatSource source={msg.sources} />}
        {msg.relatedQuestions && isLastMessage && (
          <RelatedQuestions
            questions={msg.relatedQuestions}
            onQuestionClick={handleRelatedQuestionClick}
          />
        )}
        {/* {msg.tags && <Tags tags={msg.tags} />} */}
      </Box>

      <Drawer
        anchor="right"
        open={openSourceDrawer.value}
        onClose={openSourceDrawer.onFalse}
        slotProps={{ backdrop: { invisible: true } }}
        sx={{ zIndex: 20000 }}
        PaperProps={{ sx: { width: 700 } }}
      >
        {loading ? (
          <CardShimmerEffect lineOnly={false} />
        ) : sourceItem ? (
          <SourceDrawer
            alert={{ ...sourceItem, summary: sourceItem }}
            onClose={openSourceDrawer.onFalse}
          />
        ) : (
          ''
        )}
      </Drawer>
    </Box>
  );
}
