import { m } from 'framer-motion';
import { useState, useCallback } from 'react';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { varHover } from 'src/components/animate';
import { FlagIcon, Iconify } from 'src/components/iconify';
import { usePopover, CustomPopover } from 'src/components/custom-popover';
import { useTranslate } from 'src/locales';
import { Box, Button, Stack } from '@mui/material';
import { CONFIG } from 'src/config-global';

// ----------------------------------------------------------------------

export function LanguagePopover({ data = [], type = 'default', sx }) {
  const popover = usePopover();
  const languageArray = [
    { value: 'en', label: 'English', countryCode: 'GB' },
    { value: 'pt', label: 'Português', countryCode: 'PT' },
  ];
  const { onChangeLang, currentLang } = useTranslate();

  const handleChangeLang = useCallback(
    (newLang) => {
      onChangeLang(newLang);
      popover.onClose();
    },
    [onChangeLang, popover]
  );
  return (
    <>
      {type === 'default' ? (
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={popover.onOpen}
          sx={{
            p: 0,
            width: 24,
            height: 24,
            ...(popover.open && { bgcolor: 'action.selected' }),
            ...sx,
          }}
        >
          <FlagIcon code={currentLang?.countryCode} />
        </IconButton>
      ) : (
        <Button
          disableRipple
          color="inherit"
          onClick={popover.onOpen}
          startIcon={
            <Box
              component="img"
              src={`${CONFIG.site.basePath}/assets/images/custom-icon/language_grey.svg`}
              sx={{ width: 24, height: 24, color: '#1C252E' }}
            />
          }
          endIcon={
            <Iconify
              icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            />
          }
          sx={{
            padding: '4px 8px 4px 12px',
            minWidth: '100%',
            color: 'var(--text-secondary, #637381)',
            justifyContent: 'flex-start',
            height: '44px',
            '& .MuiButton-startIcon': {
              marginRight: 1,
            },
            '& .MuiButton-endIcon': {
              marginLeft: 'auto',
            },
          }}
        >
          {currentLang?.label}
        </Button>
      )}

      <CustomPopover open={popover.open} anchorEl={popover.anchorEl} onClose={popover.onClose}>
        {type === 'default' ? (
          <MenuList sx={{ width: 160, minHeight: 72 }}>
            {languageArray?.map((option) => (
              <MenuItem
                key={option.value}
                selected={option.value === currentLang?.value}
                onClick={() => handleChangeLang(option.value)}
              >
                <FlagIcon code={option.countryCode} />
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        ) : (
          <MenuList sx={{ width: 160, minHeight: 72 }}>
            {languageArray?.map((option) => (
              <MenuItem
                key={option.value}
                // selected={option.value === currentLang?.value}
                onClick={() => handleChangeLang(option.value)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FlagIcon code={option.countryCode} />

                    {option.label}
                  </Box>
                  {option.value === currentLang?.value && (
                    <Iconify
                      icon="eva:checkmark-fill"
                      sx={{ ml: 1, width: 20, height: 20, color: 'primary.main' }}
                    />
                  )}
                </Stack>
              </MenuItem>
            ))}
          </MenuList>
        )}
      </CustomPopover>
    </>
  );
}
