import { Paper, Box, Typography, IconButton, Collapse, Drawer } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Markdown } from 'src/components/markdown';
import { useEffect, useState } from 'react';

export function Citations({ citations, handleSourceClick }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isCitations, setIsCitations] = useState('');
  // const openSourceDrawer = useBoolean();
  // const [sourceItem, setSourceItem] = useState(null);
  // const [loading, setLoading] = useState(false);
  const removeCitationsPrefix = (text) => {
    if (!text) return '';
    const citationsMarker = '#### Citations:';
    const parts = text.split(citationsMarker);

    // If no citations marker found, return original text
    if (parts.length < 2) return text;

    const text2 = parts[1].trim();
    // Replace markdown link patterns with custom markdown for onClick
    // const processedText = text2.replace(
    //   /\[([^\]]+)\]\(\/dashboard\/law-library\/pdf\/[^)]+\)/g,
    //   (match, linkText) => `[${linkText}](#)`
    // );
    return text2;
  };

  // const handlePdfClick = async (href) => {
  //   openSourceDrawer.onTrue();
  //   setLoading(true);
  //   try {
  //     const documentId = href;
  //     const response = await axiosInstance.get(`/documents/${documentId}`);

  //     // Check if response status is successful (2xx)
  //     if (response.status >= 200 && response.status < 300) {
  //       setSourceItem(response.data);
  //     } else {
  //       throw new Error(`Request failed with status ${response.status}`);
  //     }
  //   } catch (error) {
  //     toast.error('Error fetching document');

  //     openSourceDrawer.onFalse();
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const convertBlockquotesToParagraphs = (text) => {
    if (!text) return '';

    // Split text into lines
    const lines = text.split('\n');

    // Process each line
    const convertedLines = lines.map((line) => {
      // Check if line starts with '>' (blockquote marker)
      if (line.trim().startsWith('>')) {
        // Remove '>' and trim whitespace
        return line.replace(/^>\s*/, '');
      }
      return line;
    });

    // Join lines back together
    return convertedLines.join('\n');
  };

  useEffect(() => {
    if (citations) {
      setIsCitations(convertBlockquotesToParagraphs(removeCitationsPrefix(citations)));
    }
  }, [citations]);

  // Add this prop to the Markdown component
  const components = {
    a: ({ children, href }) => (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleSourceClick(href);
        }}
      >
        {children}
      </a>
    ),
  };

  return (
    <>
      <Paper sx={{ bgcolor: 'var(--shadow-12, #919EAB1F)', p: 2, mt: 2, borderRadius: 1.5 }}>
        <Box
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography
            sx={{
              fontFamily: 'var(--subtitle1family)',
              fontSize: 'var(--subtitle1size)',
              fontWeight: 600,
              lineHeight: 'var(--subtitle1line-height)',
              letterSpacing: 'var(--subtitle1letter-spacing)',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
            }}
          >
            Citations
          </Typography>
          <IconButton size="small">
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        <Collapse in={isExpanded}>
          <Box
            sx={{ pl: 2, borderLeft: '5px solid var(--icon-color-neutral-inset-color, #C5CAD17A)' }}
          >
            <Markdown
              components={components}
              sx={{
                fontFamily: 'var(--captionfamily)',
                fontSize: 'var(--captionsize)',
                fontStyle: 'italic',
                fontWeight: 400,
                lineHeight: 'var(--captionline-height)',
                letterSpacing: 'var(--captionletter-spacing)',
                textAlign: 'left',

                borderRadius: 2,
              }}
            >
              {isCitations}
            </Markdown>
          </Box>
        </Collapse>
      </Paper>
    </>
  );
}
