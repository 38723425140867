import { countriesAlpha3 } from 'src/_mock/_map/countries';

export const SECURITY_REQUIREMENT = ['yes', 'no'];
export const YES_NO = SECURITY_REQUIREMENT;
export const SECURITY_REQUIREMENT_BOOLEAN = [true, false];

export const LAW_LIBRARY_TABLE_HEAD = [
  {
    id: 'title',
    isInternal: true,
    internalOrder: 1,
    externalOrder: 1,
    isExternal: true,
    label: 'Title',
  },
  {
    id: 'jurisdiction',
    isInternal: true,
    internalOrder: 2,
    externalOrder: 2,
    isExternal: true,
    label: 'Jurisdiction',
  },
  {
    id: 'legislationType',
    isInternal: false,
    internalOrder: 11111,
    externalOrder: 3,
    isExternal: true,
    label: 'Legislation Type',
  },
  {
    id: 'status',
    isInternal: false,
    internalOrder: 1111,
    externalOrder: 4,
    isExternal: false,
    label: 'Status',
  },
  {
    id: 'securityRequirement',
    isInternal: false,
    internalOrder: 1111,
    externalOrder: 5,
    isExternal: true,
    label: 'Security Requirement',
  },
  {
    id: 'entryIntoForce',
    isInternal: false,
    internalOrder: 1111,
    externalOrder: 6,
    isExternal: true,
    label: 'Entry into Force',
  },
  {
    id: 'applicableFrom',
    isInternal: false,
    internalOrder: 1111,
    externalOrder: 7,
    isExternal: true,
    label: 'Applicable From',
  },
  {
    id: 'lastUpdated',
    isInternal: false,
    internalOrder: 1111,
    externalOrder: 8,
    isExternal: true,
    label: 'Last Updated',
  },
  {
    id: 'applicableDepartments',
    isInternal: true,
    internalOrder: 8,
    externalOrder: 9,
    isExternal: true,
    label: 'Applicable Departments',
  },
  {
    id: 'relevant',
    isInternal: false,
    internalOrder: 1111,
    externalOrder: 10,
    isExternal: false,
    label: 'Relevant',
  },
  {
    id: 'owner',
    isInternal: false,
    internalOrder: 1111,
    externalOrder: 11,
    isExternal: true,
    label: 'Owner',
  },
  {
    id: 'actions',
    isInternal: true,
    internalOrder: 13,
    externalOrder: 12,
    isExternal: true,
    label: 'Actions',
  },
  {
    id: 'complianceArea',
    isInternal: true,
    internalOrder: 3,
    externalOrder: 13,
    isExternal: false,
    label: 'Compliance Area',
  },
  {
    id: 'nextReview',
    isInternal: true,
    internalOrder: 4,
    externalOrder: 2222,
    isExternal: false,
    label: 'Next Review',
  },
  {
    id: 'lastReviewed',
    isInternal: true,
    internalOrder: 5,
    externalOrder: 2222,
    isExternal: false,
    label: 'Last Reviewed',
  },
  {
    id: 'effectiveDate',
    isInternal: true,
    internalOrder: 6,
    externalOrder: 2222,
    isExternal: false,
    label: 'Effective Date',
  },
  {
    id: 'reviewCycle',
    isInternal: true,
    internalOrder: 7,
    externalOrder: 2222,
    isExternal: false,
    label: 'Review Cycle',
  },
  {
    id: 'documentOwner',
    isInternal: true,
    internalOrder: 9,
    externalOrder: 2222,
    isExternal: false,
    label: 'Document Owner',
  },
  {
    id: 'approver',
    isInternal: true,
    internalOrder: 10,
    externalOrder: 2222,
    isExternal: false,
    label: 'Approver',
  },
  {
    id: 'approvalStatus',
    isInternal: true,
    internalOrder: 11,
    externalOrder: 2222,
    isExternal: false,
    label: 'Approval Status',
  },
  {
    id: 'version',
    isInternal: true,
    internalOrder: 12,
    externalOrder: 2222,
    isExternal: false,
    label: 'Version',
  },
  // { id: 'relatedLegislation', isInternal:true, internalOrder: 20, externalOrder: 20, isExternal:true, label: 'Related Legislation' },
];

export const getFlagByCode = (code) => {
  //  const country = countriesAlpha3.find((item) => item.alpha3Code === code)
  //  if(!country) {

  //  }
  return (
    countriesAlpha3.find((item) => item.alpha3Code === code || item.code === code) || { code: code }
  );
};

export const APPLICABLE_DEPARTMENTS = [
  // "Compliance", "Finance", "HR",
  // 'title',
  // 'jurisdiction',
  // 'legislationType',
  // 'status',
  // 'relatedLegislation',
  'securityRequirement',
];

export const APPLICABLE_DEPARTMENTS_LIST = [
  {
    value: 'compliance',
    name: 'Compliance',
  },
  {
    value: 'credit',
    name: 'Credit',
  },
  {
    value: 'customer-success',
    name: 'Customer Success',
  },
  {
    value: 'engineering',
    name: 'Engineering',
  },
  {
    value: 'finance',
    name: 'Finance',
  },
  {
    value: 'financial-crime',
    name: 'Financial Crime',
  },
  {
    value: 'infosec',
    name: 'InfoSec',
  },
  {
    value: 'it',
    name: 'IT',
  },
  {
    value: 'legal',
    name: 'Legal',
  },
  {
    value: 'onboarding',
    name: 'Onboarding',
  },
  {
    value: 'people',
    name: 'People',
  },
  {
    value: 'risk-control',
    name: 'Risk Control',
  },
];

export const DOCUMENT_STATUS = ['Active', 'Enacted', 'Processed', 'Proposed', 'Suspended'];

export const DOCUMENT_STATUS_COLOR = {
  proposed: 'var(--warning-dark, #B76E00)',
  active: 'var(--primary-main, #00A76F)',
  suspended: 'var(--grey-600, #637381)',
  enacted: 'var(--secondary-dark, #5119B7)',
  processed: 'var(--info-main, #00B8D9)',
};

export const REVIEW_CYCLE = [
  'daily',
  'weekly',
  'monthly',
  'quarterly',
  'biannual',
  'half yearly',
  'annual',
];
export const APPROVERS_LIST = [
  'CEO',
  'CFO',
  'CPO',
  'CTO',
  'Head of Compliance',
  'Head of Customer Success',
  'Head of Data',
  'Head of Engineering',
  'Head of Finance',
  'Head of HR',
  'Head of IT',
  'Head of Legal',
  'Head of Marketing',
  'Head of Operations',
  'Head of Product',
  'Head of Risk',
  'Head of Sales',
  'Head of Security',
];
export const LEGISLATION_TYPES = [
  'Directive',
  'Guideline',
  'Regulation',
  'Regulatory Technical Standard',
];

export const REQUIREMENT_OPTIONS = [
  {
    value: 'obligations',
    label: 'Obligation',
  },
  {
    value: 'control',
    label: 'Control',
  },

  {
    value: 'conditions',
    label: 'Conditions',
  },
  {
    value: 'impact',
    label: 'Impact',
  },
  {
    value: 'consequences',
    label: 'Consequences',
  },
];

export const REQUIREMENT_STATUS = [
  {
    value: 'needs-review',
    label: 'Needs Review',
    color: '#B76E00',
    icon: 'mynaui:circle-dashed-solid',
    bgColor: '#ffab00',
    count: 0,
  },
  {
    value: 'reviewed',
    label: 'Reviewed',
    color: '#006C9C',
    icon: 'solar:bolt-bold',
    bgColor: '#006C9C',
    count: 0,
  },
  {
    value: 'in-progress',
    label: 'In Progress',
    color: '#7635CB',
    icon: 'solar:hourglass-line-bold',
    count: 0,
  },
  {
    value: 'implemented',
    label: 'Implemented',
    color: '#118D57',
    icon: 'solar:check-circle-bold',
    count: 0,
  },
];
export const REQUIREMENT_DISCARD_FILTER = ['active', 'discard'];
export const REQUIREMENT_PRE_FIX = ['REQ-', 'CTR-', 'CND-', 'OBL-', 'CSN-'];

export const CONTROL_RISK_LEVEL = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
];

export const CONTROL_SCOPE_OF_CONTROL = [
  { value: 'department', label: 'Department' },
  { value: 'organization', label: 'Organization' },
  { value: 'process', label: 'Process' },
];
export const TRAINING_REQUIRED = ['required', 'optional', 'none'];
export const CONTROL_TYPES = [
  { value: 'corrective', label: 'Corrective' },
  { value: 'detective', label: 'Detective' },
  { value: 'preventive', label: 'Preventive' },
];

export const CONTROL_AUTOMATED_MANUAL = [
  { value: 'automated', label: 'Automated' },
  { value: 'manual', label: 'Manual' },
];
