import { useState, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import ChatIcon from '@mui/icons-material/Chat';
import ChatInterface from './chat-interface';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useChatStore } from 'src/zustand-store/chat-store/chat-store';
import { useAuthContext } from 'src/auth/hooks';
import Popover from '@mui/material/Popover';

const chatButtonStyles = {
  position: 'fixed',
  bottom: 20,
  right: 20,
  backgroundColor: 'primary.main',
  color: 'white',
  borderRadius: '24px',
  padding: '10px 20px',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: 'primary.dark',
    transform: 'translateY(-2px)',
    transition: 'transform 0.2s',
  },
  '&:active': {
    transform: 'translateY(0)',
  },
};

const popoverPaperStyles = {
  pointerEvents: 'auto',
  position: 'fixed',
  bottom: 30,
  right: 20,
  m: 0,
  width: '480px',
  height: '80vh',
  border: 1,
  boxShadow: 'var(--z16x) var(--z16y) var(--z16blur) var(--z16spread) var(--shadow16)',
  borderColor: 'divider',
  background: '#FFFFFF',
};

const ChatWindow = ({ id, type, copiedText, setCopiedText }) => {
  const { sendMessage, setInput } = useChatStore();
  const { user } = useAuthContext();
  const [open, setOpen] = useState(Boolean(copiedText));
  // Memoize handlers
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setCopiedText?.('');
  }, [setCopiedText]);

  const handlePopoverClose = useCallback(
    (event, reason) => {
      if (reason === 'backdropClick') return;
      handleClose();
    },
    [handleClose]
  );

  // Memoize popover props
  const popoverProps = useMemo(
    () => ({
      open,
      anchorEl: document.body,
      onClose: handlePopoverClose,
      disableRestoreFocus: true,
      disableScrollLock: true,
      disablePortal: true,
      keepMounted: true,
      disableEnforceFocus: true,
      disableAutoFocus: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      transformOrigin: { vertical: 'bottom', horizontal: 'right' },
      slotProps: {
        paper: {
          sx: popoverPaperStyles,
          role: 'complementary',
          'aria-label': 'Chat window',
        },
      },
      sx: { pointerEvents: 'none' },
    }),
    [open]
  );

  // Handle copied text effect
  useEffect(() => {
    if (copiedText) {
      // sendMessage(copiedText, user, id, type);
      setInput(copiedText);

      setOpen(true);
    }
  }, [copiedText, sendMessage, user]);

  return (
    <>
      {!open && (
        <Button onClick={handleClickOpen} startIcon={<ChatIcon />} sx={chatButtonStyles}>
          Zango Copilot
        </Button>
      )}

      <Popover {...popoverProps}>
        <Box sx={{ height: '100%', overflowY: 'auto' }}>
          {open && (
            <ChatInterface
              copiedText={copiedText}
              handleClose={handleClose}
              id={id}
              isWindow={true}
              type={type}
            />
          )}
          {/* <GeminiChatInterface handleClose={handleClose} id={id} isWindow={true} type={type} /> */}
        </Box>
      </Popover>
    </>
  );
};

export default ChatWindow;
