import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  Stack,
  MenuList,
  MenuItem,
  Typography,
} from '@mui/material';
import { Iconify } from 'src/components/iconify';
import SendIcon from '@mui/icons-material/Send';
import { CONFIG } from 'src/config-global';
import { SvgColor } from 'src/components/svg-color';
import { useEffect, useRef, useState } from 'react';
import { usePopover, CustomPopover } from 'src/components/custom-popover';
import { useChatStore } from 'src/zustand-store/chat-store/chat-store';

export default function ChatInputField({
  input,
  setInput,
  handleSend,
  isConnected,
  files = [],
  handleDocChange,
  isWindow,
  isLoading,
  chatFrom = '',
}) {
  const [imagePreview, setImagePreview] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(files && files.length > 0 ? files[0] : null);
  const [searchTerm, setSearchTerm] = useState('');
  const filePopover = usePopover();
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type.startsWith('image/') ||
        [
          'application/pdf',
          'text/plain',
          'text/html',
          'text/md',
          'text/csv',
          'text/xml',
          'text/rtf',
        ].includes(file.type))
    ) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];

        const fileData = {
          filename: file.name,
          content_type: file.type, // mandatory field
          data: base64String, // base64 encoded file content
        };
        setImagePreview((prev) => [
          ...[],
          {
            type: file.type,
            name: file.name,
            file: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
          },
        ]);
        useChatStore.getState().setImageData(fileData);
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    } else {
      alert('Please select an image file');
    }
  };

  const handleFileSelect = (file) => {
    handleDocChange(file);
    setSelectedFile(file);
    filePopover.onClose();
  };

  useEffect(() => {
    // This cleanup function runs when the component unmounts or when imagePreview changes
    return () => {
      // Clean up any object URLs created for image previews
      if (imagePreview && imagePreview.length > 0) {
        imagePreview.forEach((preview) => {
          if (preview.file) {
            // Release the memory used by each object URL to prevent memory leaks
            URL.revokeObjectURL(preview.file);
          }
        });
      }
    };
  }, []);
  const cancelFileIcon = (index) => (
    <IconButton
      size="small"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '2px',
      }}
      onClick={() => {
        useChatStore.getState().removeImageData(index);
        setImagePreview(imagePreview.filter((_, i) => i !== index));
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }}
    >
      <Iconify
        icon="eva:close-fill"
        width={15}
        sx={{ bgcolor: 'black', color: 'white', borderRadius: '50%' }}
      />
    </IconButton>
  );

  const imagePreviewSection = (preview, index) => (
    <Box sx={{ position: 'relative', width: 40, ml: 0 }}>
      <Box
        component="img"
        src={preview.file}
        title={preview.name}
        sx={{
          width: 40,
          height: 40,
          borderRadius: '10px',

          objectFit: 'cover',

          border: '1px solid #919EAB52',
        }}
      ></Box>
      {cancelFileIcon(index)}
    </Box>
  );
  const filePreviewSection = (preview, index) => (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: 1,
        p: 1,
        maxWidth: '260px',
        mr: 1.5,
      }}
    >
      <Box
        component="img"
        src={`${CONFIG.site.basePath}/assets/icons/files/${
          preview.type === 'application/pdf'
            ? 'ic-pdf.svg'
            : preview.type === 'text/csv'
              ? 'ic-csv.svg'
              : 'ic-document.svg'
        }`}
        sx={{
          width: 40,
          height: 40,
          maxWidth: 40,
        }}
        title={preview.name}
      />
      <Typography
        variant="caption"
        sx={{
          ml: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        }}
      >
        {preview.name}
      </Typography>
      {cancelFileIcon(index)}
    </Box>
  );
  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        handleSend(e);
        setImagePreview(null);
      }}
      sx={{
        width: '100%',
        mt: 4,
        ...(!isWindow
          ? {
              maxWidth: 800,
              mx: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }
          : {
              borderTop: '1px solid var(--components-button-outlined, #919EAB52)',
            }),
      }}
    >
      {/* {imagePreview && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Box
            component="img"
            src={imagePreview.file}
            alt={imagePreview.name}
            sx={{ width: 100, height: 100, borderRadius: '4px', mr: 1 }}
          />
          <IconButton onClick={() => setImagePreview(null)}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </Box>
      )} */}

      {/* <Stack direction="row" spacing={0} sx={{ alignItems: 'center', py: 1, px: 0 }}> */}
      {/* Uploaded Images Section */}

      <TextField
        fullWidth
        placeholder="Type your message..."
        value={input}
        sx={{
          background: '#ffffff',
          fontFamily: 'var(--body2family)',
          fontSize: 'var(--body2size)',
          fontWeight: 400,
          ...(!isWindow
            ? {
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '3px solid var(--components-button-outlined, #919EAB52)',
                    borderRadius: '16px',
                  },
                },
                '& .MuiOutlinedInput-root:hover': {
                  '& fieldset': {
                    border: '3px solid var(--components-button-outlined, #919EAB52)',
                  },
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& fieldset': {
                    border: '3px solid var(--components-button-outlined, #919EAB52)',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  border: 'none',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& fieldset': {
                    border: 'none',
                  },
                },
              }),
          lineHeight: 'var(--body2line-height)',
          letterSpacing: 'var(--body2letter-spacing)',
          textAlign: 'left',
        }}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {/* {imagePreview ? (
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      component="img"
                      src={imagePreview}
                      sx={{
                        width: 40,
                        maxWidth: 40,
                        height: 40,
                        borderRadius: 1,
                        objectFit: 'cover',
                        ml: -0.5,
                        border: '1px solid #919EAB52',
                      }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: -4,
                        right: -4,
                        padding: '2px',
                      }}
                      onClick={() => {
                        setImagePreview(null);
                        if (fileInputRef.current) {
                          fileInputRef.current.value = '';
                        }
                      }}
                    >
                      <Iconify
                        icon="eva:close-fill"
                        width={15}
                        sx={{ bgcolor: 'black', color: 'white', borderRadius: '50%' }}
                      />
                    </IconButton>
                  </Box>
                ) : (
                  <SvgColor src={`${CONFIG.site.imageBasePath}/assets/icons/navbar/ic-chat.svg`} />
                )} */}
              {!chatFrom && files && files.length > 0 ? (
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{ flexWrap: 'wrap', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <Chip
                    onClick={filePopover.onOpen}
                    variant="default"
                    icon={
                      selectedFile && selectedFile.icon ? (
                        <Box
                          component="img"
                          src={`${CONFIG.site.basePath}/assets/icons/files/ic-${selectedFile.icon}.svg`}
                          sx={{ width: 16, height: 16, mr: 1 }}
                        />
                      ) : null
                    }
                    label={
                      selectedFile
                        ? selectedFile.name.length > 35
                          ? `${selectedFile.name.slice(0, 35)}...`
                          : selectedFile.name
                        : 'Select File'
                    }
                    onDelete={filePopover.onOpen}
                    deleteIcon={<Iconify icon="mdi:chevron-down" />}
                  />

                  <CustomPopover
                    open={filePopover.open}
                    anchorEl={filePopover.anchorEl}
                    onClose={filePopover.onClose}
                  >
                    <Box sx={{ p: 2, position: 'sticky', top: 0, zIndex: 1 }}>
                      <TextField
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search files..."
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                          ),
                        }}
                        sx={{ width: { xs: 1, md: 260 } }}
                      />
                    </Box>
                    <MenuList sx={{ width: 300, maxHeight: 300, overflow: 'auto' }}>
                      {files
                        .filter((file) =>
                          file.name.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((file) => (
                          <MenuItem
                            key={file.id}
                            onClick={() => handleFileSelect(file)}
                            sx={{
                              backgroundColor:
                                selectedFile && selectedFile.id === file.id
                                  ? 'action.selected'
                                  : 'inherit',
                              '&:hover': { backgroundColor: 'action.hover' },
                            }}
                          >
                            <Box
                              component="img"
                              src={`${CONFIG.site.basePath}/assets/icons/files/ic-pdf.svg`}
                              sx={{ width: 16, height: 16, mr: 1 }}
                            />
                            <Typography variant="body2" noWrap>
                              {file.name}
                            </Typography>
                          </MenuItem>
                        ))}
                    </MenuList>
                  </CustomPopover>
                </Stack>
              ) : (
                <>
                  {imagePreview && imagePreview.length > 0 ? null : (
                    <SvgColor
                      src={`${CONFIG.site.imageBasePath}/assets/icons/navbar/ic-chat.svg`}
                    />
                  )}
                </>
              )}
              {imagePreview && imagePreview.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', overflowX: 'auto' }}>
                  {imagePreview.map((preview, index) => (
                    <Box
                      key={preview.name + index}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0 }}
                    >
                      {preview.type.startsWith('image/')
                        ? imagePreviewSection(preview, index)
                        : filePreviewSection(preview, index)}
                    </Box>
                  ))}
                </Box>
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <Box display="flex" alignItems="center" gap={1}>
              {chatFrom == 'marketing-copilot' && (
                <>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    // accept="image/*,application/pdf,text/plain,text/html,text/md,text/csv,text/xml,text/rtf"
                    hidden
                    onChange={handleImageUpload}
                  />
                  <IconButton disabled={isLoading} onClick={() => fileInputRef.current?.click()}>
                    <Iconify icon="eva:attach-2-fill" />
                  </IconButton>
                </>
              )}

              <IconButton type="submit" color="primary" disabled={isLoading || !input}>
                {/* <Iconify icon="solar:round-arrow-right-up-bold" sx={{ height: 32, width: 32 }} /> */}
                <SendIcon />
              </IconButton>
            </Box>
          ),
        }}
      />
      {/* {isWindow && (
          <Box display="flex" alignItems="center" gap={1}>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*,application/pdf,text/plain,text/html,text/md,text/csv,text/xml,text/rtf"
              hidden
              onChange={handleImageUpload}
            />
            <IconButton disabled={isLoading} onClick={() => fileInputRef.current?.click()}>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>

            <IconButton type="submit" color="primary" disabled={isLoading || !input}>
              <Iconify icon="solar:round-arrow-right-up-bold" sx={{ height: 32, width: 32 }} />
            </IconButton>
          </Box>
        )} */}
      {/* </Stack> */}

      {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {files && files.length > 0 && (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ flexWrap: 'wrap', justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <Chip
              onClick={filePopover.onOpen}
              variant="default"
              title={selectedFile?.name}
              sx={{
                height: 40,
                bgcolor: '#ffffff',
                '&:hover': {
                  bgcolor: '#ffffff',
                },
              }}
              icon={
                selectedFile && selectedFile.icon ? (
                  <Box
                    component="img"
                    src={`${CONFIG.site.basePath}/assets/icons/files/ic-${selectedFile.icon}.svg`}
                    sx={{ width: 16, height: 16, mr: 1 }}
                  />
                ) : null
              }
              label={
                selectedFile
                  ? selectedFile.name.length > 35
                    ? `${selectedFile.name.slice(0, 35)}...`
                    : selectedFile.name
                  : 'Select File'
              }
              onDelete={filePopover.onOpen}
              deleteIcon={<Iconify icon="mdi:chevron-down" />}
            />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*,application/pdf,text/plain,text/html,text/md,text/csv,text/xml,text/rtf"
              hidden
              onChange={handleImageUpload}
            />
            <IconButton disabled={isLoading} onClick={() => fileInputRef.current?.click()}>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>
            <CustomPopover
              open={filePopover.open}
              anchorEl={filePopover.anchorEl}
              onClose={filePopover.onClose}
            >
              <Box sx={{ p: 2, position: 'sticky', top: 0, zIndex: 1 }}>
                <TextField
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search files..."
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: { xs: 1, md: 260 } }}
                />
              </Box>
              <MenuList sx={{ width: 350, maxHeight: 350, overflow: 'auto' }}>
                {files
                  .filter((file) => file.name.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map((file) => (
                    <MenuItem
                      key={file.id}
                      onClick={() => handleFileSelect(file)}
                      sx={{
                        backgroundColor:
                          selectedFile && selectedFile.id === file.id
                            ? 'action.selected'
                            : 'inherit',
                        '&:hover': { backgroundColor: 'action.hover' },
                      }}
                    >
                      <Box
                        component="img"
                        src={`${CONFIG.site.basePath}/assets/icons/files/ic-pdf.svg`}
                        sx={{ width: 16, height: 16, mr: 1 }}
                      />
                      <Typography variant="body2">{file.name}</Typography>
                    </MenuItem>
                  ))}
              </MenuList>
            </CustomPopover>
          </Stack>
        )}

        {!isWindow && (
          <Box>
            <IconButton type="submit" color="primary" disabled={isLoading || !input}>
              <Iconify icon="solar:round-arrow-right-up-bold" sx={{ height: 32, width: 32 }} />
            </IconButton>
          </Box>
        )}
      </Box> */}
    </Box>
  );
}
