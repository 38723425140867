import PropTypes from 'prop-types';
import { Fab, Typography, Box } from '@mui/material';

export default function LangChangeFabBtn({ currentLang, onChangeLang, disabled = false }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {currentLang === 'en' ? (
        <>
          <Fab
            color="primary"
            size="small"
            disabled={disabled}
            sx={{
              width: 36,
              height: 36,
              boxShadow: 'none',
              '&:hover': {
                bgcolor: 'primary.main',
              },
            }}
          >
            {currentLang.toUpperCase()}
          </Fab>
          <Typography
            variant="body2"
            onClick={() => !disabled && onChangeLang('pt')}
            sx={{ cursor: disabled ? '' : 'pointer' }}
            fontWeight="normal"
          >
            {'PT'}
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="body2"
            onClick={() => onChangeLang('en')}
            sx={{ cursor: 'pointer' }}
            fontWeight="normal"
          >
            {'EN'}
          </Typography>
          <Fab
            color="primary"
            size="small"
            sx={{
              width: 36,
              height: 36,
              boxShadow: 'none',
              '&:hover': {
                bgcolor: 'primary.main',
              },
            }}
          >
            {'PT'}
          </Fab>
        </>
      )}
    </Box>
  );
}

LangChangeFabBtn.propTypes = {
  currentLang: PropTypes.string.isRequired,
  onChangeLang: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
