import { Box, ListItem, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import { Iconify } from 'src/components/iconify';
import { CONFIG } from 'src/config-global';

export function RelatedQuestions({ questions, onQuestionClick, user }) {
  const [loadingIndex, setLoadingIndex] = useState(null);
  if (!Array.isArray(questions) || questions.length === 0) {
    return null;
  }
  return (
    <Box sx={{ mt: 3 }}>
      <Typography
        sx={{
          mb: 1,
          fontFamily: 'var(--subtitle2family)',
          fontSize: 'var(--subtitle2size)',
          fontWeight: 600,
          lineHeight: 'var(--subtitle2line-height)',
          letterSpacing: 'var(--subtitle2letter-spacing)',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
        }}
      >
        Follow-up questions
      </Typography>
      {questions.map((question, index) => (
        <ListItem
          key={index}
          sx={{ py: 2, px: 0, cursor: 'pointer' }}
          onClick={() => {
            setLoadingIndex(index);
            if (!loadingIndex) {
              onQuestionClick(question, user);
            }
          }}
        >
          {loadingIndex === index ? (
            <Box
              component="img"
              src={`${CONFIG.site.basePath}/assets/images/custom-icon/loading.svg`}
              sx={{
                ml: 1,
                mr: 1.5,
                width: 18,
                height: 18,
                cursor: 'pointer',
                filter: 'hue-rotate(125deg)',
              }}
              loading="lazy"
              fetchpriority="low"
            />
          ) : (
            <ListItemIcon>
              <Iconify icon="iconoir:flash" sx={{ color: 'primary.main', fontSize: 20 }} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: 'var(--captionline-height)',
                  letterSpacing: 'var(--captionletter-spacing)',
                  textAlign: 'left',
                  color: '#000000',
                }}
              >
                {question}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </Box>
  );
}
