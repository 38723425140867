import { Box, Typography, Button, Collapse, Divider } from '@mui/material';
import React, { useState } from 'react';
import { Iconify } from 'src/components/iconify';
import { fToNow } from 'src/utils/format-time';

export default function ChatSidebar() {
  const dummyData = [
    {
      title: 'Project Kickoff Meeting',
      description: 'Initial meeting to discuss project goals and timeline',
      date: '2023-06-15',
    },
    {
      title: 'Quarterly Financial Review',
      description: 'Analysis of Q2 financial performance and projections',
      date: '2023-07-01',
    },
    {
      title: 'New Product Launch',
      description: 'Strategy session for upcoming product release',
      date: '2023-07-10',
    },
  ];
  return (
    <Box>
      <AccordionSection title="Saved  Chat " icon="ic:round-star" data={dummyData} /> <Divider />
      <AccordionSection title="History" icon="solar:clock-circle-outline" data={dummyData} />{' '}
    </Box>
  );
}

const AccordionSection = React.memo(({ title, icon, data }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Box>
      <Button
        disableRipple
        color="inherit"
        onClick={() => setIsExpanded(!isExpanded)}
        endIcon={
          <Iconify
            sx={{ color: '#637381', mr: 4 }}
            icon={isExpanded ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        }
        sx={{
          px: 2,
          py: 1,
          minWidth: '100%',
          display: 'flex',
          fontSize: 16,
          fontWeight: 500,
          color: '#1C252E',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .MuiButton-endIcon': {
            ml: 'auto',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* <Box sx={{ mr: 2 }}> */}
          <Iconify sx={{ color: '#637381', mr: 2 }} icon={icon} />
          {/* </Box> */}
          <Typography
            sx={{
              fontFamily: 'var(--subtitle2family)',
              fontSize: 'var(--subtitle2size)',
              fontWeight: 600,
              lineHeight: 'var(--subtitle2line-height)',
              letterSpacing: 'var(--subtitle2letter-spacing)',
              textAlign: 'left',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Button>

      <Collapse in={isExpanded}>
        <Box sx={{ px: 2, py: 1 }}>
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Box sx={{ mr: 2 }}>
                <Iconify sx={{ color: '#637381' }} icon={icon} />
              </Box>
              <Box sx={{ flex: 1, overflow: 'hidden' }}>
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: 200,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: 'var(--subtitle2size)',
                    fontWeight: 500,
                    lineHeight: 'var(--subtitle2line-height)',
                    letterSpacing: 'var(--subtitle2letter-spacing)',
                    color: 'var(--text-primary, #1C252E)',
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: 250,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Inter',
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: '14.52px',
                    letterSpacing: 'var(--body2letter-spacing)',
                    color: 'var(--text-secondary, #637381)',
                  }}
                >
                  {item.description}
                </Typography>
              </Box>
              <Box sx={{ mx: 2 }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 'var(--captionsize)',
                    fontWeight: 400,
                    lineHeight: 'var(--captionline-height)',
                    letterSpacing: 'var(--captionletter-spacing)',
                    textAlign: 'right',
                    color: 'var(--text-disabled, #919EAB)',
                  }}
                >
                  {fToNow(new Date(item.date))}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
});
