import React, { useState, useEffect, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { highlightPlugin, MessageIcon } from '@react-pdf-viewer/highlight';
import { MenuList, MenuItem, ListItemIcon, ListItemText, Box, Typography } from '@mui/material';
import {
  ContentCopy as ContentCopyIcon,
  Share as ShareIcon,
  Delete as DeleteIcon,
  FormatColorText as HighlightIcon,
} from '@mui/icons-material';
import { searchPlugin } from '@react-pdf-viewer/search';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import axiosInstance from 'src/utils/axios';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import CardShimmerEffect from 'src/components/loading-screen/card-shimmer-effect';
import ChatWindow from '../ask-zango/view/chat-window';
import { toast } from 'sonner';
import { CONFIG } from 'src/config-global';

const MyPDFViewer = ({ fileUrl, lawId, referenceText = null, pageNumber = null }) => {
  const [error, setError] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [copiedText, setCopiedText] = useState('');
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  // Reference to store the viewer instance
  const viewerRef = useRef(null);

  const handleClose = (props) => {
    if (props.toggle && typeof props.toggle === 'function') {
      props.toggle();
    }
  };
  const handleOpenWindow = (text) => {
    setCopiedText(text);
  };
  const renderHighlightTarget = (props) => {
    if (pageNumber !== null && typeof +pageNumber === 'number') {
      return null;
    }
    if (
      !props?.selectionRegion ||
      typeof props?.selectionRegion?.left !== 'number' ||
      typeof props?.selectionRegion?.top !== 'number' ||
      typeof props?.selectionRegion?.height !== 'number'
    ) {
      return null;
    }
    const copyText = () => {
      navigator.clipboard.writeText(props?.selectedText || '');
      toast.success('Text copied to clipboard');
      handleClose(props);
    };
    return (
      <div
        role="dialog"
        aria-label="Text selection options"
        style={{
          display: 'flex',
          position: 'absolute',
          left: `${Math.max(0, props?.selectionRegion?.left)}%`,
          top: `${Math.max(0, props?.selectionRegion?.top + props?.selectionRegion?.height)}%`,
          transform: 'translate(0, 8px)',
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 3,
            px: 2,
            py: 1,
            position: 'absolute',
            zIndex: 1000,
          }}
          role="menu"
          aria-label="Selection options menu"
        >
          <MenuList>
            <MenuItem
              onClick={() => {
                handleOpenWindow(props?.selectedText || '');
                handleClose(props);
              }}
              role="menuitem"
              tabIndex={0}
            >
              <ListItemIcon>
                <MessageIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Ask Zango</ListItemText>
            </MenuItem>

            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <HighlightIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Highlight Text</ListItemText>
            </MenuItem> */}

            <MenuItem onClick={() => copyText(props)} role="menuitem" tabIndex={0}>
              <ListItemIcon>
                <ContentCopyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy Text</ListItemText>
            </MenuItem>

            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <ShareIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Share</ListItemText>
            </MenuItem> */}

            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem> */}
          </MenuList>
        </Box>
      </div>
    );
  };
  // Search plugin to find and navigate to the reference text
  const searchPluginInstance = searchPlugin();
  const { highlight, Search } = searchPluginInstance;

  // Reference to store the search instance
  const searchInstanceRef = useRef(null);
  // Add a ref to track if highlighting has been done
  const highlightedRef = useRef(false);

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    onTextSelection: (content, selection) => {
      try {
        // Make sure we have valid selection data
        if (!selection || !selection.rangeCount) return null;
        if (!content || !content.selectionRegion) return null;

        // Validate the selection region properties
        const { selectionRegion } = content;
        if (
          typeof selectionRegion.left !== 'number' ||
          typeof selectionRegion.top !== 'number' ||
          typeof selectionRegion.height !== 'number'
        ) {
          return null;
        }

        // Check for valid selected text
        if (!content.selectedText || content.selectedText.trim() === '') {
          return null;
        }

        return renderHighlightTarget({
          selectionRegion: content.selectionRegion,
          selectedText: content.selectedText,
          toggle: content.toggle,
        });
      } catch (error) {
        console.error('Text selection error:', error);
        return null;
      }
    },
  });

  // Add the page navigation plugin
  const pageNavigationPluginInstance = pageNavigationPlugin();

  // Store the plugin instance reference
  const pageNavigationRef = useRef(null);

  const handleError = (e) => {
    setError('Failed to load PDF file. Please try again later.');
  };

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance(fileUrl, {
          method: 'GET',
          credentials: 'include',
          responseType: 'arraybuffer',
          headers: {
            'Cache-Control': 'public, max-age=3600', // Cache for 1 hour
            Expires: new Date(Date.now() + 3600 * 1000).toUTCString(), // Set expiration to 1 hour in the future
            Pragma: 'cache', // Remove any headers that prevent caching
          },
        });

        if (!response.data) {
          throw new Error('No data received from server');
        }

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfData(url);
      } catch (fetchError) {
        setError(`Failed to fetch PDF: ${fetchError.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPdf();

    return () => {
      if (pdfData) {
        URL.revokeObjectURL(pdfData);
      }
    };
  }, [fileUrl]);

  // Find and highlight reference text after PDF is loaded
  // useEffect(() => {
  //   if (pdfData && referenceText && searchInstanceRef.current && !highlightedRef.current) {
  //     // Wait for PDF to be fully loaded before searching
  //     const timer = setTimeout(() => {
  //       // Normalize the reference text by replacing line breaks with space
  //       const normalizedText = referenceText ? referenceText.replace(/\n/g, ' ') : '';
  //       console.log('normalizedText', normalizedText);

  //       // Search for reference text and highlight all instances
  //       if (normalizedText) {
  //         highlight(normalizedText);
  //         // Mark as highlighted so we don't keep scrolling back
  //         highlightedRef.current = true;
  //       }
  //     }, 1000); // Give PDF time to load

  //     return () => clearTimeout(timer);
  //   }
  // }, [pdfData, referenceText, highlight]);

  // Reset highlight flag when PDF or reference text changes
  // useEffect(() => {
  //   highlightedRef.current = false;
  // }, [fileUrl, referenceText]);

  // Function to handle viewer ready state
  // const handleViewerReady = (viewer) => {
  //   // Store search functionality for later use
  //   if (viewer) {
  //     searchInstanceRef.current = searchPluginInstance;
  //     viewerRef.current = viewer;

  //     if (pageNumber !== null && typeof +pageNumber === 'number') {
  //       console.log('pageNumber', pageNumber);

  //       // PDF pages are 0-indexed internally, but usually displayed as 1-indexed
  //       const pageIndex = Math.max(0, +pageNumber - 1);

  //       // Use setTimeout to ensure the viewer is fully initialized
  //       setTimeout(() => {
  //         if (pageNavigationRef.current) {
  //           pageNavigationRef.current.jumpToPage(pageIndex);
  //         }
  //       }, 500);
  //     }
  //   }
  // };

  // Effect to handle jumping to specific page when viewer is ready and pageNumber changes
  // useEffect(() => {
  //   if (viewerRef.current && pageNumber !== null && typeof pageNumber === 'number') {
  //     // PDF pages are 0-indexed internally, but usually displayed as 1-indexed
  //     const pageIndex = Math.max(0, pageNumber - 1);
  //     viewerRef.current.jumpToPage(pageIndex);
  //   }
  // }, [pageNumber]);

  return (
    <Box>
      {loading ? (
        // <CardShimmerEffect sx={{ height: '90vh' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '12px',
            }}
          >
            <Box
              component="img"
              src={`${CONFIG.site.basePath}/assets/images/custom-icon/loading_nodata.gif`}
              sx={{
                width: '47px',
                height: '48px',
                filter: 'grayscale(1)',
              }}
            ></Box>
            <Typography
              sx={{
                color: 'var(--text-secondary, #637381)',
                fontFamily: 'var(--body2-family, Inter)',
                fontSize: 'var(--body2-size, 14px)',
                fontStyle: 'normal',
                fontWeight: 'var(--body2-weight, 400)',
                lineHeight: 'var(--body2-line-height, 22px)' /* 157.143% */,
                letterSpacing: 'var(--body2-letter-spacing, 0px)',
              }}
            >
              {'Loading Document please wait...'}
            </Typography>
          </Box>
        </Box>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <div
              className="rpv-core__viewer"
              style={{
                border: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                height: '90vh',
                p: 4,
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  bgcolor: 'background.neutral',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  padding: '4px',
                }}
              >
                <Toolbar>
                  {(props) => {
                    const {
                      CurrentPageInput,
                      Download,
                      EnterFullScreen,
                      GoToNextPage,
                      GoToPreviousPage,
                      NumberOfPages,
                      Print,
                      ShowSearchPopover,
                      Zoom,
                      ZoomIn,
                      ZoomOut,
                      // Theme,
                    } = props;
                    return (
                      <>
                        <div style={{ padding: '0px 2px' }}>
                          <ShowSearchPopover />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                          <ZoomOut />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                          <Zoom />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                          <ZoomIn />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                          <GoToPreviousPage />
                        </div>
                        <div style={{ padding: '0px 2px', width: '4rem' }}>
                          <CurrentPageInput />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                          / <NumberOfPages />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                          <GoToNextPage />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                          <EnterFullScreen />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                          <Download />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                          <Print />
                        </div>
                        {/* <div style={{ padding: '0px 2px' }}>
                        <Theme />
                      </div> */}
                      </>
                    );
                  }}
                </Toolbar>
              </div>
              <div style={{ flex: 1, overflow: 'hidden' }}>
                {pdfData && (
                  <Viewer
                    fileUrl={pdfData}
                    onError={handleError}
                    plugins={[
                      toolbarPluginInstance,
                      highlightPluginInstance,
                      searchPluginInstance,
                      pageNavigationPluginInstance,
                    ]}
                    initialPage={pageNumber - 1 > 0 ? pageNumber - 1 : 0}
                    // onDocumentLoad={(e) => {
                    //   // Store the pageNavigation API when document loads
                    //   pageNavigationRef.current = pageNavigationPluginInstance;
                    //   handleViewerReady(e);
                    // }}
                  />
                )}
              </div>
              {/* <div style={{ display: 'none' }}>
              <Search />
            </div> */}
            </div>
          </Worker>
          {lawId && (
            <ChatWindow
              id={lawId}
              type={'policy_id'}
              copiedText={copiedText}
              setCopiedText={setCopiedText}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default MyPDFViewer;
