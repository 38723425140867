import { paths } from 'src/routes/paths';

import packageJson from '../package.json';

// ----------------------------------------------------------------------
export const USER_TYPE = ['nb', 'copilot'];
export const USER_EMAIL = ['demo@zango.ai', 'support+monzo@zango.ai'];
export const ORG_LIST = [
  {
    org_id: null,
    name: 'No User',
    support_email: 'demo@zango.ai',
    allowed_feature: {
      isRequirementOn: false,
      docIdForRequirement: [],
    },
  },
  {
    org_id: '019123ec-7133-7fae-95bd-98a3eee0140d',
    name: 'Demo',
    support_email: 'demo@zango.ai',
    allowed_feature: {
      isRequirementOn: true,
      docIdForRequirement: [
        '0194796a-042e-7307-b5a8-14cba4c97573',
        '0194f675-cede-7531-a680-f7ca1a06251f',
      ],
    },
  },
  {
    org_id: '01953912-ab91-7b84-8c47-a87ff93a5b05',
    name: 'Monzo',
    support_email: 'support+monzo@zango.ai',
    allowed_feature: {
      isRequirementOn: true,
      docIdForRequirement: ['019585ba-b0ed-7ed3-ba08-50edbcd4493a'],
    },
  },
  {
    org_id: '01944bc5-bef0-7414-85cf-b76f7c72d012',
    name: 'NovaBank',
    support_email: 'support+nb@zango.ai',
    allowed_feature: {
      isRequirementOn: true,
      docIdForRequirement: [],
    },
  },
  {
    org_id: '0195712d-3dcf-736c-8569-b5e019a2b43e',
    name: 'Nyaz_ORG_1',
    support_email: 'nyaz+1@zango.ai',
    allowed_feature: {
      isRequirementOn: false,
      docIdForRequirement: [],
    },
  },
  {
    org_id: '01957138-541d-72ac-841e-ce42d04bdc8a',
    name: 'Nyaz_ORG_2',
    support_email: 'nyaz+4@zango.ai',
    allowed_feature: {
      isRequirementOn: false,
      docIdForRequirement: [],
    },
  },
];

export const CONFIG = {
  site: {
    name: 'Zango AI',
    serverUrl: process.env.REACT_APP_SERVER_URL || 'https://api.dev.zango.ai',
    webSocketURL: process.env.REACT_APP_WEB_SOCKET || 'wss://api.dev.zango.ai',
    assetURL: process.env.REACT_APP_ASSET_URL || '',
    basePath: process.env.REACT_APP_BASE_PATH || '',
    imageBasePath: process.env.REACT_APP_IMAGE_BASE_PATH || '',
    version: packageJson.version,
    slackInstallUrl: process.env.REACT_APP_SLACK_INSTALL_URL || 'https://slack.dev.zango.ai',
    geminiApiUrl:
      process.env.REACT_GEMINI_API_URL ||
      'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-pro-002:streamGenerateContent',
    geminiApiKey: process.env.REACT_GEMINI_API_KEY || 'AIzaSyAxn0CE4J2RJv0vL1HYjw5Q5DSeG1bJBSA',
  },
  // isStaticExport: JSON.parse(`${process.env.BUILD_STATIC_EXPORT}`),
  /**
   * Auth
   * @method firebase  | amplify | jwt | supabase | auth0
   */
  auth: {
    method: 'firebase',
    skip: false,
    redirectPath: paths.dashboard.alerts,
  },
  /**
   * Mapbox
   */
  // mapbox: {
  //   apiKey: process.env.NEXT_PUBLIC_MAPBOX_API_KEY ?? '',
  // },
  /**
   * Firebase
   */
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    dbBucket: process.env.REACT_APP_DB_BUCKET || 'zango-dev',
    refreshTokenInterval: 20 * 60 * 1000, // 20 minutes in milliseconds
  },

  featureToggle: {
    onBoarding: {
      root: process.env.REACT_APP_FEATURE_ONBOARDING_ROOT === 'true',
    },
    dashboard: {
      root: process.env.REACT_APP_FEATURE_DASHBOARD_ROOT === 'true',
    },
    askZango: {
      root: process.env.REACT_APP_FEATURE_ASK_ZANGO_ROOT === 'true',
    },
    alert: {
      root: process.env.REACT_APP_FEATURE_ALERT_ROOT === 'true',
      filter: {
        topics: process.env.REACT_APP_FEATURE_ALERT_FILTER_TOPICS === 'true',
        agencyUpdates: process.env.REACT_APP_FEATURE_ALERT_FILTER_AGENCY_UPDATES === 'true',
        regulationUpdates: process.env.REACT_APP_FEATURE_ALERT_FILTER_REGULATION_UPDATES === 'true',
      },
      toolbar: {
        askZango: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_ASK_ZANGO === 'true',
        notification: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_NOTIFICATION === 'true',
        dots: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_DOTS === 'true',
      },
      tableData: {
        dots: process.env.REACT_APP_FEATURE_ALERT_TABLE_DATA_DOTS === 'true',
      },
      summary: {
        root: process.env.REACT_APP_FEATURE_ALERT_SUMMARY_ROOT === 'true',
        toolbar: {
          askZango: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_ASK_ZANGO === 'true',
          impactAssesment: process.env.REACT_APP_FEATURE_IMPACT_ASSESMENT_ROOT === 'true',
          dots: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_DOTS === 'true',
        },
      },
    },
    gapAnalysis: {
      root: process.env.REACT_APP_FEATURE_GAP_ANALYSIS_ROOT === 'true',
    },
    lawLibrary: {
      root: process.env.REACT_APP_FEATURE_LAW_LIBRARY_ROOT === 'true',
    },
    internalPolicy: {
      root: process.env.REACT_APP_FEATURE_INTERNAL_POLICY_ROOT === true,
    },
    risk: {
      root: process.env.REACT_APP_FEATURE_RISK_ROOT === 'true',
    },
    control: {
      root: process.env.REACT_APP_FEATURE_CONTROL_ROOT === 'true',
    },
    task: {
      root: process.env.REACT_APP_FEATURE_TASK_ROOT === 'true',
    },
    impactAssesment: {
      root: process.env.REACT_APP_FEATURE_IMPACT_ASSESMENT_ROOT === 'true',
    },
    comments: {
      root: process.env.REACT_APP_FEATURE_COMMENTS_ROOT === 'true',
    },
  },
};
