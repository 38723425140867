import { create } from 'zustand';
import axiosInstance from 'src/utils/axios';
import { fDate, formatStr } from 'src/utils/format-time';
import { LAW_LIBRARY_TABS_MAP, POLICY_STATUS_OPTIONS } from 'src/constant/filters';
import { paths } from 'src/routes/paths';
import { toast } from 'sonner';

const defaultFilters = {
  name: '',
  page: 0,
  tabFilter: [LAW_LIBRARY_TABS_MAP[0].value],
  applicable: true,
  status: [],
  jurisdictions: [],
  legislationType: [],
  securityRequirement: [],
  entryIntoForce: [],
  applicableFrom: [],
  lastUpdated: [],
  applicableDepartments: [],
  entryIntoForceDate: {
    startDate: null,
    endDate: null,
  },
  applicableFromDate: {
    startDate: null,
    endDate: null,
  },
  lastUpdatedDate: {
    startDate: null,
    endDate: null,
  },

  // Add other relevant filters as needed
};

const useLawLibraryStore = create((set, get) => ({
  data: [],
  isLoading: false,
  error: null,
  dataSummary: null,
  summaryError: null,
  isLoadingSummary: false,

  relatedAlerts: {
    isLoading: false,
    data: [],
    error: null,
  },
  relatedLegislation: {
    isLoading: false,
    data: [],
    error: null,
  },
  createPolicy: {
    isLoading: false,
    data: null,
    error: null,
    success: null,
  },

  timeStamp: null,
  isInternalPolicies: false,
  jurisdictions: [],

  dataFiltered: [],
  dataInPage: [],
  notFound: false,
  canReset: false,
  canResetAll: false,

  filters: (() => {
    try {
      const storedFilters = JSON.parse(localStorage.getItem('lawLibraryFilters'));
      return storedFilters || defaultFilters;
    } catch {
      return defaultFilters;
    }
  })(),
  // Fetch all police data
  fetchData: async () => {
    const url = window.location.pathname;
    const isInternalPolicies = url.includes(paths.dashboard.internalPolicies);
    set({ isInternalPolicies });
    set({ isLoading: true });
    try {
      const response = await axiosInstance.get('/policies');
      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(fDate(a.updated_at) || 0);
        const dateB = new Date(fDate(b.updated_at) || 0);
        return dateB - dateA;
      });
      set({
        data: sortedData,
        isLoading: false,
        error: null,
        timeStamp: fDate(new Date(), formatStr.dateTime),
      });
      get().applyFilter();
    } catch (error) {
      set({ error: error.message, isLoading: false, timeStamp: new Date() });
    }
  },
  setError: (error) => {
    set({ error });
  },
  setSummaryError: (error) => {
    set({ summaryError: error });
  },
  // Fetch single police data by ID
  fetchDataById: async (id) => {
    set({ isLoadingSummary: true });
    try {
      const response = await axiosInstance.get(`/policies/${id}`);
      set({
        dataSummary: response.data,
        isLoadingSummary: false,
        summaryError: null,
      });
    } catch (error) {
      set({ summaryError: error.message, isLoadingSummary: false });
    }
  },
  // Fetch related alerts for a policy
  fetchRelatedAlerts: async (policyId) => {
    set({
      relatedAlerts: {
        isLoading: true,
        data: [],
        error: null,
      },
    });
    try {
      const response = await axiosInstance.get(`/policies/${policyId}/related?relation_type=alert`);
      set({
        relatedAlerts: {
          isLoading: false,
          data: response.data,
          error: null,
        },
      });
      return response.data;
    } catch (error) {
      set({
        relatedAlerts: {
          isLoading: false,
          data: [],
          error: error.message,
        },
      });
      throw error;
    }
  },

  fetchRelatedLegislation: async (policyId) => {
    set({
      relatedLegislation: {
        isLoading: true,
        data: [],
        error: null,
      },
    });
    try {
      const response = await axiosInstance.get(`/policies/${policyId}/related`);
      set({
        relatedLegislation: {
          isLoading: false,
          data: response.data.map((item) => ({
            ...item,
            countryCode: item.countryCode || ' ',
          })),
          error: null,
        },
      });
      return response.data;
    } catch (error) {
      set({
        relatedLegislation: {
          isLoading: false,
          data: [],
          error: error.message,
        },
      });
      throw error;
    }
  },
  // Update filters
  setFilters: (newFilters) => {
    set((state) => {
      const updatedFilters = { ...state.filters, ...newFilters, page: 0 };
      localStorage.setItem('lawLibraryFilters', JSON.stringify(updatedFilters));
      return { filters: updatedFilters };
    });
    get().applyFilter();
  },
  updateLawLibraryApplicable: async (id, isApplicable, isSummary = false) => {
    const endpoint = `policies/${id}/applicable`;
    if (!isSummary) {
      get().updateLawLibrary({
        ...get().data.find((policy) => policy.id === id),
        applicable: isApplicable,
      });
    }
    try {
      const response = await axiosInstance({
        method: isApplicable ? 'put' : 'delete',
        url: endpoint,
      });
      if (response.status === 200) {
        if (isSummary) {
          get().updateSummary({
            ...get().dataSummary,
            applicable: isApplicable,
          });
        } else {
          get().updateLawLibrary({
            ...get().data.find((policy) => policy.id === id),
            applicable: isApplicable,
          });
        }
        return true;
      }
      throw new Error('Unexpected response status');
    } catch (error) {
      if (!isSummary) {
        get().updateLawLibrary({
          ...get().data.find((policy) => policy.id === id),
          applicable: !isApplicable,
        });
      }
      throw error;
    }
  },
  updateLawLibraryExtra: async (id, data, isExtra = false) => {
    let tempData = get().data.find((policy) => policy.id === id);
    const updateLawLibraryApi = async (id, data, isExtra) => {
      try {
        const response = await axiosInstance.patch(
          `/policies/${id}`,
          isExtra ? { extra: data } : data
        );
        if (response.status === 200) {
          get().updateLawLibrary({
            ...tempData,
            ...(!isExtra ? data : { extra: data }),
          });
          toast.success(response.data?.message || ' Updated successfully');

          return true;
        }
        throw new Error('Unexpected response status');
      } catch (error) {
        get().updateLawLibrary({
          ...tempData,
        });
        toast.error(error.response?.data?.message || 'Failed to update');

        throw error;
      }
    };

    updateLawLibraryApi(id, data, isExtra);
  },
  updateLawLibraryStar: async (id, isStarred) => {
    // Optimistically update the UI
    get().updateLawLibrary({
      ...get().data.find((policy) => policy.id === id),
      star: isStarred,
    });

    try {
      const response = await axiosInstance({
        method: isStarred ? 'put' : 'delete',
        url: `policies/${id}/star`,
      });

      if (response.status === 200) {
        // toast.success(response.data?.message || 'Star status updated successfully');
        return true;
      }
      throw new Error('Unexpected response status');
    } catch (error) {
      // Revert the optimistic update on error
      get().updateLawLibrary({
        ...get().data.find((policy) => policy.id === id),
        star: !isStarred,
      });
      // toast.error(error.response?.data?.message || 'Failed to update star status');
      throw error;
    }
  },
  updateFilters: (newFilters, isFetchAlerts = false) => {
    set((state) => {
      const updatedFilters = { ...state.filters, ...newFilters, page: 0 };
      if (!isFetchAlerts) {
        localStorage.setItem('lawLibraryFilters', JSON.stringify(updatedFilters));
      }
      return { filters: updatedFilters };
    });

    get().applyFilter();
  },
  updateSummary: (updatedSummary) => {
    set({ dataSummary: updatedSummary });
    get().applyFilter();
  },
  updateLawLibrary: (updatedLawLibrary) => {
    set((state) => ({
      data: state.data.map((item) => (item.id === updatedLawLibrary.id ? updatedLawLibrary : item)),
    }));
    get().applyFilter();
  },
  // Apply filters to data
  applyFilter: () => {
    const { data, isInternalPolicies, filters } = get();
    const {
      name,
      tabFilter,
      applicable,
      status,
      jurisdictions,
      legislationType,
      securityRequirement,
      entryIntoForce,
      applicableFrom,
      lastUpdated,
      applicableDepartments,
    } = filters;

    let filteredData = [...data];
    filteredData = filteredData.filter((data) => data.status === POLICY_STATUS_OPTIONS[1]);
    filteredData = filteredData.filter((data) => {
      return isInternalPolicies ? !data?.is_regulation : data?.is_regulation;
    });

    if (name) {
      filteredData = filteredData.filter(
        (item) => item.name?.toLowerCase().indexOf(name.toLowerCase()) !== -1
      );
    }
    if (tabFilter?.length && tabFilter[0] === LAW_LIBRARY_TABS_MAP[1].value) {
      filteredData = filteredData.filter((item) => {
        return item.star;
      });
    }
    if (applicableDepartments && applicableDepartments.length > 0) {
      filteredData = filteredData.filter((item) => {
        if (!item.extra || !item.extra.org_units) return false;
        if (!Array.isArray(item.extra.org_units)) return false;
        return item?.extra?.org_units.some((unit) =>
          applicableDepartments.some((s) => s?.name?.toLowerCase() === unit?.toLowerCase())
        );
      });
    }

    if (jurisdictions && jurisdictions.length > 0) {
      filteredData = filteredData.filter((item) => {
        const hasEmptyCode = jurisdictions.some((j) => j.code === '');
        const hasOtherJurisdictions = jurisdictions.some((j) => j.code !== '');

        // Check if item has no jurisdictions when empty code is selected
        const matchesEmptyCode =
          hasEmptyCode &&
          (!item.extra?.jurisdictions ||
            !Array.isArray(item.extra?.jurisdictions) ||
            item.extra.jurisdictions.length === 0);

        // Check if item matches any non-empty jurisdiction codes
        const matchesJurisdictions =
          hasOtherJurisdictions &&
          item.extra?.jurisdictions &&
          Array.isArray(item.extra.jurisdictions) &&
          item.extra.jurisdictions.some((country) =>
            jurisdictions.some((s) => s?.code?.toLowerCase() === country?.code?.toLowerCase())
          );

        // Return true if matches empty code OR matches other jurisdictions
        return matchesEmptyCode || matchesJurisdictions;
      });
    }
    if (legislationType && legislationType?.length > 0) {
      filteredData = filteredData.filter((item) => {
        if (!item.extra || !item?.extra?.document_type) return false;
        return legislationType.some(
          (type) => type.toLowerCase() === item.extra?.document_type?.toLowerCase()
        );
      });
    }
    if (securityRequirement && securityRequirement?.length > 0) {
      filteredData = filteredData.filter((item) => {
        // Filter for 'no' selection
        if (securityRequirement.includes('yes') && securityRequirement.includes('no')) {
          return true;
        }
        if (securityRequirement.includes('no')) {
          return !item.extra || item.extra?.has_security_requirements !== true;
        }

        // Filter for 'yes' selection
        if (securityRequirement.includes('yes')) {
          return item?.extra && item?.extra?.has_security_requirements === true;
        }
      });
    }

    if (status && status?.length > 0) {
      filteredData = filteredData.filter((item) => {
        if (!item.extra || !item?.extra?.document_status) return false;
        return status.some(
          (statusFilter) =>
            statusFilter.toLowerCase() === item.extra?.document_status?.toLowerCase()
        );
      });
    }

    // if (applicable) {
    //   filteredData = filteredData.filter((item) => item.applicable);
    // }
    const canReset =
      !!name ||
      legislationType?.length > 0 ||
      securityRequirement?.length > 0 ||
      entryIntoForce?.length > 0 ||
      applicableFrom?.length > 0 ||
      lastUpdated?.length > 0 ||
      applicableDepartments?.length > 0;

    set({
      dataFiltered: filteredData,
      notFound: (!filteredData?.length && canReset) || !filteredData?.length,
      canReset,
      canResetAll: canReset || !applicable || status?.length > 0 || jurisdictions?.length > 0,
    });

    get().updateDataInPage();
  },

  // Update data in current page
  updateDataInPage: () => {
    const { dataFiltered, filters } = get();
    const { page, rowsPerPage = 10 } = filters;
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    set({ dataInPage: dataFiltered.slice(start, end) });
  },
  setCreatePolicy: (data) => {
    set({
      createPolicy: data,
    });
  },
  resetFilters: () => {
    set({ filters: defaultFilters });
    get().applyFilter();
  },

  createNewPolicy: async (formData, { onUploadProgress } = {}) => {
    set({
      createPolicy: {
        isLoading: true,
        data: null,
        error: null,
        success: null,
      },
    });
    try {
      const response = await axiosInstance.post('/policies', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });
      set({
        createPolicy: {
          isLoading: false,
          data: response.data,
          error: null,
          success: true,
        },
      });
      set({
        data: [...get().data, response.data],
      });
      return response.data;
    } catch (error) {
      set({
        createPolicy: {
          isLoading: false,
          data: null,
          error: error.message,
          success: false,
        },
      });
      throw error;
    }
  },
}));

export default useLawLibraryStore;
