import React from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Collapse,
  CardActionArea,
  Card,
  Drawer,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { Markdown } from 'src/components/markdown';
import { Iconify } from 'src/components/iconify';
import { SourceDrawer } from 'src/components/source-drawer';
import { useBoolean } from 'src/hooks/use-boolean';

export function ChatSource({ source = [] }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const openSourceDrawer = useBoolean();
  const [sourceItem, setSourceItem] = useState(null);
  const handleSourceDrawer = (item) => {
    setSourceItem(item);
    openSourceDrawer.setValue(true);
  };
  return (
    <Paper sx={{ bgcolor: 'var(--shadow-12, #919EAB1F)', p: 2, mt: 2, borderRadius: 1.5 }}>
      <Box
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography
          sx={{
            fontFamily: 'var(--subtitle1family)',
            fontSize: 'var(--subtitle1size)',
            fontWeight: 600,
            lineHeight: 'var(--subtitle1line-height)',
            letterSpacing: 'var(--subtitle1letter-spacing)',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
          }}
        >
          Source
          <Box
            component="span"
            sx={{
              ml: 1,
              height: 20,
              minWidth: 20,
              lineHeight: 0,
              borderRadius: 1,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'inline-flex',
              padding: '0px 6px',
              fontSize: '0.75rem',
              fontWeight: '500',
              backgroundColor: 'rgba(145, 158, 171, 0.16)',
              color: '#637381',
            }}
          >
            {source.length}
          </Box>
        </Typography>
        <IconButton size="small">
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <Box
          sx={{
            pl: 2,
            overflowX: 'auto',
            display: 'flex',
            gap: 2,
            py: 1, // Add padding to show scrollbar
            // '&::-webkit-scrollbar': {
            //   height: 8,
            // },
            // '&::-webkit-scrollbar-track': {
            //   backgroundColor: 'grey.100',
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   backgroundColor: 'grey.400',
            //   borderRadius: 4,
            // },
          }}
        >
          {source.length > 0 ? (
            source.map((item) => (
              <Box
                key={item.id}
                onClick={() => {
                  handleSourceDrawer(item);
                }}
                sx={{
                  minWidth: 250,
                  flexShrink: 0,
                  p: 2,
                  color: 'primary.main',
                  minHeight: 60,
                  display: 'flex',
                  gap: 1,
                  flexDirection: 'row',
                  alignItems: 'top',
                  // justifyContent: 'space',
                  cursor: 'pointer',
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  boxShadow: 1,
                  '&:hover': {
                    // bgcolor: 'primary.main',
                  },
                }}
              >
                <Iconify icon="ic:outline-arrow-outward" width={24} height={24} />
                <Box
                  sx={{
                    maxWidth: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {item?.content?.substring(0, 32)?.replace(/[^a-zA-Z0-9]/g, ' ')}
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
              No source available
            </Typography>
          )}
        </Box>
      </Collapse>
      <Drawer
        anchor="right"
        open={openSourceDrawer.value}
        onClose={openSourceDrawer.onFalse}
        slotProps={{ backdrop: { invisible: true } }}
        sx={{ zIndex: 20000 }}
        PaperProps={{ sx: { width: 700, backgroundImage: 'none' } }}
      >
        <SourceDrawer
          alert={{ ...sourceItem, ...{ summary: sourceItem } }}
          onClose={openSourceDrawer.onFalse}
        />
      </Drawer>
    </Paper>
  );
}
