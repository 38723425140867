import { Alert, Box, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';

export default function TranslationAlertToast({
  isRefiningTranslation,
  cancelTranslation,
  updateTranslation,
}) {
  return (
    <Box display="flex" justifyContent="center">
      {/* Loading Alert */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          transform: 'translateX(0)',
          zIndex: 1000,
          maxWidth: '600px',
          width: '90%',
          display: isRefiningTranslation ? 'block' : 'none',
        }}
      >
        <Alert
          severity="info"
          sx={{
            borderRadius: 1.5,
            p: 2,
            border: '1px solid var(--components-paper-outlined, rgba(145, 158, 171, 0.12))',
            background: 'var(--grey-200, #F4F6F8)',
            boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
            '& .MuiAlert-message': {
              padding: '8px 0',
              color: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            },
          }}
          icon={false}
        >
          <Box
            component="span"
            sx={{
              width: 24,
              height: 24,
              borderRadius: '50%',
              borderTop: '2px solid',
              borderColor: 'primary.main',
              display: 'inline-block',
              animation: 'spin 1s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
            }}
          />
          A refinar a tradução para este resumo, por favor aguarde...
        </Alert>
      </Box>

      {/* Original Alert */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          transform: 'translateX(0)',
          zIndex: 1000,
          maxWidth: '600px',
          width: '90%',

          display: !isRefiningTranslation ? 'block' : 'none',
        }}
      >
        <Alert
          severity="info"
          sx={{
            p: 2,
            borderRadius: 1.5,
            border: '1px solid var(--components-paper-outlined, rgba(145, 158, 171, 0.12))',
            background: 'var(--grey-200, #F4F6F8)',
            boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
            '& .MuiAlert-action': {
              alignItems: 'center',
              paddingTop: 0,
            },
            '& .MuiAlert-message': {
              padding: '8px 0',
              color: 'text.primary',
            },
            display: 'flex',
            alignItems: 'center',
          }}
          icon={
            <Box
              component="img"
              src="/assets/images/custom-icon/language_grey.svg"
              sx={{ width: 24, height: 24 }}
            />
          }
          action={
            <Stack direction="row" spacing={1}>
              <Button
                variant="text"
                color="inherit"
                size="m"
                onClick={cancelTranslation}
                sx={{ color: 'text.secondary' }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                size="m"
                onClick={updateTranslation}
                sx={{
                  mr: 1,
                  bgcolor: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
              >
                Aceitar Tradução
              </Button>
            </Stack>
          }
        >
          Deseja aceitar nova tradução?
        </Alert>
      </Box>
    </Box>
  );
}

TranslationAlertToast.propTypes = {
  isRefiningTranslation: PropTypes.bool.isRequired,
  cancelTranslation: PropTypes.func.isRequired,
  updateTranslation: PropTypes.func.isRequired,
};
