import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { usePathname, useRouter } from 'src/routes/hooks';
import { navData } from '../config-nav-dashboard';

import { Iconify } from 'src/components/iconify';
import useAlertStore from 'src/zustand-store/alert-store/alert-store';
import { useTranslate } from 'src/locales';
// import { useChatStore } from 'src/zustand-store/chat-store/chat-store';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const PageTitle = ({ currentNav, backButton }) => {
  const router = useRouter();
  const { t } = useTranslate('common');
  const { alertTitle } = useAlertStore();

  const handleNavigation = () => {
    router.push(currentNav?.path);
  };
  // const selectedOption = useChatStore((state) => state.selectedOption);
  return (
    <StyledBox>
      {backButton && alertTitle ? (
        <Iconify
          onClick={() => handleNavigation()}
          icon="ic:round-arrow-back"
          width={14}
          sx={{ cursor: 'pointer' }}
        />
      ) : (
        currentNav?.icon
      )}
      {/* {!backButton && currentNav?.icon} */}
      <Typography
        sx={{ fontWeight: 500, fontSize: '14px', cursor: 'pointer' }}
        onClick={backButton ? () => handleNavigation() : undefined}
      >
        {t(currentNav?.title)} {'   '}
        {/* {selectedOption ? selectedOption.name : ''} */}
      </Typography>

      {backButton && alertTitle ? (
        <>
          <Iconify icon="formkit:right" width={16} />
          <Typography
            sx={{
              fontWeight: 500,
              maxWidth: '450px',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={alertTitle}
          >
            {alertTitle}
          </Typography>
        </>
      ) : null}
    </StyledBox>
  );
};
