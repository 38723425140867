import { Box, Button } from '@mui/material';

import { Iconify } from 'src/components/iconify';
import { useChatStore } from 'src/zustand-store/chat-store/chat-store';
import { useChatStoreBeta } from 'src/zustand-store/chat-store/gemini-store';
import { paths } from 'src/routes/paths';

export default function ChatHeader({ currentNav, backButton }) {
  const { resetChat, setIsMarketingChat } = useChatStore();
  // const { resetChatBeta } = useChatStoreBeta();
  const handleSelection = (item) => {};

  const handleNewChat = () => {
    if (currentNav.path === paths?.dashboard?.askZango) {
      resetChat();
      setIsMarketingChat(false);
    } else {
      resetChat();
      setIsMarketingChat(true);
    }
  };
  return (
    <Box
    // sx={{
    //     py: 2,
    //     px: 2.5,
    //     display: 'flex',
    //     alignItems: 'center',
    //     gap: 1,
    //     borderBottom: `1px solid ${theme.palette.divider}`,
    //     backgroundColor: '#fbfbfb'
    // }}
    >
      <Button
        variant="soft"
        color="inherit"
        size="small"
        startIcon={<Iconify icon="mingcute:add-line" />}
        onClick={() => handleNewChat()}
      >
        New Chat
      </Button>
      {/*  <Checkbox
        color="warning"
        icon={<Iconify color="#919EAB" icon="eva:star-outline" />}
        checkedIcon={<Iconify icon="eva:star-fill" />}
        sx={{ p: 0.75 }}
      />
      <Checkbox
        icon={<Iconify color="#919EAB" icon="mingcute:fullscreen-2-line" />}
        checkedIcon={<Iconify color="#919EAB" icon="mingcute:fullscreen-exit-2-line" />}
        checked={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        sx={{ p: 0.75 }}
      /> */}
      {/* <Iconify
             
                icon={!isExpanded ? 'ic:round-close-fullscreen' : 'mi:expand'}
                sx={{ px: 0.75, width: 48, height: 48, cursor: 'pointer', }}
                onClick={() => setIsExpanded(!isExpanded)}
            /> */}
      {/* <StyledDivider /> */}
      {/* 
            <Box sx={{ flexGrow: 1 }} /> */}

      {/* <CommonShareBtn handleSelection={handleSelection} /> */}
    </Box>
  );
}
